import React, { useContext } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { NominationContext } from "./NominationContext";
import ReactExport from 'react-data-export';
import moment from 'moment';

const NominationExport = () => {
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const nominationModel = useContext(NominationContext);
  const {
    nominationsfiltered
  } = nominationModel;

   return (
        <ExcelFile filename="FBOA-Nominations" element={<button className="btn btn-danger pull-right">Export to Excel</button>}>
            <ExcelSheet data={nominationsfiltered} name="Employees">
                <ExcelColumn label="Position" value={(col) => (col.association?col.association.name:"")}/>
                <ExcelColumn label="Name" value="name"/>
                <ExcelColumn label="Designation" value="designation"/>
                <ExcelColumn label="PF Number" value="pfNumber"/>
                <ExcelColumn label="Phone" value="primaryContactNumber"/>
                <ExcelColumn label="Email" value="email"/>
                <ExcelColumn label="Branch" value="branch"/>
                <ExcelColumn label="Address" value="address"/>
                <ExcelColumn label="Status" value={(col) => (col.status.toLowerCase()==="withdraw"?"Withdrawn":col.status)}/>
                <ExcelColumn label="Timestamp" value={(col) => moment(col.createdAt).format("MMMM DD, yyyy hh:mm a")}/>
            </ExcelSheet>
        </ExcelFile>

  );
}

export default NominationExport;