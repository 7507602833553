import React from "react";
import PositionCard from "../components/Position/PositionCard";
import Sidebar from "../components/Sidebar";
import { PositionContextProvider } from "../components/Position/PositionContext";

const PositionPage = () => {
  return (
    <div>
      <PositionContextProvider>
        <Sidebar />
        <PositionCard />
      </PositionContextProvider>
    </div>
  );
};

export default PositionPage;
