import React, { useContext } from "react";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import PencilIcon from "mdi-react/PencilIcon";
import DeleteIcon from "mdi-react/DeleteIcon";
import ContainerForTable from "../ContainerForTable";
import { NotificationContext } from "./NotificationContext";
import PopupC from "../PopupC";
import PopupD from "../PopupD";

const NotificationCard = () => {
  const notificationModel = useContext(NotificationContext);
  const {
    show,
    notifications,
    columnData,
    setTitle,
    notificationTitle,
    description,
    setShow,
    setDescription,
    titleValidation,
    setTitleValidation,
    descriptionValidation,
    setDescriptionValidation,
    onTargetZoneSelect,
    targetZone,
    zoneList,
    scheduleDate,
    onScheduleDatechange,
    scheduleNotification,
    sendNotification,
    setZoneValidation,
    zoneValidation,
    setDateValidation,
    dateValidation,
    showPopup,
    setShowPopup,
    isSuccess,
    onConfirmClick,
    message,
    setMessageData,
    columListData,
    sendMessage,
    messageData
  } = notificationModel;
  const { SearchBar } = Search;
  const handleShow = () => { };
  const rowClasses = (row) => (row.status === "Scheduled" ? "pendingRow" : "");
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: "Last",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });

  return (
    <div>
      {/* {columListData?.length > 0 ? (
        <ContainerForTable
          title={"Notification Logs"}
          subTitle={"Message Logs"}
          handleShow={handleShow}
          products={notifications}
          columns={columnData}
          SearchBar={SearchBar}
          pagination={pagination}
          rowClasses={rowClasses}
          columListData={columListData}
        />
      ) : ( */}

        <div className="form-group" style={{ paddingTop: '80px', marginLeft: '300px', marginBottom: '5px' }}>
          <h4 className="mr-4 mb-2 font-weight-bold">
            Send Messages
          </h4>
          <textarea className="form-control" value ={messageData} id="smsTextArea" rows="8" style={{ maxWidth: '500px', }} placeholder="Type message here..." onChange={(e)=>{setMessageData(e.target.value)}}></textarea>
          <button type="button" className="btn btn-primary mt-2" onClick={sendMessage}>
            Send SMS
          </button>
        </div>
      {/* )} */}

      {/****************** Modal Popup *********************/}
      <PopupC
        title={"Send Notifications"}
        show={show}
        dropListTitle={"Title"}
        inputValue={setTitle}
        selectedItem={notificationTitle}
        descriptionTitle={"Message"}
        setDescription={setDescription}
        description={description}
        setShow={setShow}
        titleValidation={titleValidation}
        setTitleValidation={setTitleValidation}
        descriptionValidation={descriptionValidation}
        setDescriptionValidation={setDescriptionValidation}
        onTargetZoneSelect={onTargetZoneSelect}
        targetZone={targetZone}
        zoneList={zoneList}
        scheduleDate={scheduleDate}
        onScheduleDatechange={onScheduleDatechange}
        scheduleNotification={scheduleNotification}
        sendNotification={sendNotification}
        setZoneValidation={setZoneValidation}
        zoneValidation={zoneValidation}
        setDateValidation={setDateValidation}
        dateValidation={dateValidation}
      />
      <PopupD
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        isSuccess={isSuccess}
        onConfirmClick={onConfirmClick}
        message={message}
        isWarning={false}
      />
    </div>
  );
};

export default NotificationCard;
