import React, { useContext } from 'react';
import Candidate from "../../assets/images/img-avatar.png"
import { NominationDetailsContext } from './NominationDetailsContext';
import NominationDetailsTable from './NominationDetailsTable';
import NominationRejectPopup from './NominationRejectPopup';
import { store } from "../../store/store";

const NominationDetailsCard = () => {
      const globalState = useContext(store);
      const nominationDetailsModel = useContext(NominationDetailsContext);
      const {
        nominationDetails,
        setRejectNominationClick,
        onApproved,
        showMandatoryValidation,
        rejectNomination,
        rejectionNote,
        setRejectionNote,
        onRejected,
        setShowMandatoryValidation
      } = nominationDetailsModel;

    return (
    <div>
        <div className="main-panel">
        <div className="content-wrapper">
          <div className="row">
            <div className="col-md-12 grid-margin">
              <div className="d-flex justify-content-between flex-wrap">
                <div className="d-flex align-items-center dashboard-header flex-wrap mb-3 mb-sm-0">
                  <h4 className="mr-4 mb-0 font-weight-bold">Nomination</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">

                  <form>
                    <div  className="row mt-4">
                  <div className="col-md-6 detailBox">
                      <div className="row"> 
                      <div className="col-md-4">
                        <div className="img-box " style={{margin:"auto"}}>
                            <img src={nominationDetails&& nominationDetails.profileImage?nominationDetails.profileImage:Candidate} onError={(e) => { e.target.onerror = null; e.target.src = Candidate }} alt="" className="imgdiv"/> 
                        </div>
                        <label className="d-flex justify-content-center mt-2">Candidate Name</label>
                          <span className="d-flex justify-content-center aligncenter detailBoxBold">{nominationDetails?nominationDetails.name:""}</span> 
                      </div>
                      <div className="col-md-8 detailBox">
                        <label className="required">Position Applied for</label><br/>
                        <div className="detailBoxBold">{nominationDetails && nominationDetails.association?nominationDetails.association.name:"-"}</div><br/> 

                        <label className="required mt-4">branch & Address</label><br/>
                        <div className={"detailBoxBold"}>
                            {nominationDetails && nominationDetails.designation &&
                            <div className={"divspace detailBoxBold"}>{nominationDetails.designation}</div>
                            }
                            <div className={"divspace detailBoxBold "+ nominationDetails && (nominationDetails?.branch!=="" || nominationDetails?.address!=="")?"display-block":"display-none"}>{(nominationDetails && nominationDetails?.branch? nominationDetails.branch+' Branch':"")+(nominationDetails && nominationDetails?.branch && nominationDetails?.branch!=="" && nominationDetails?.address!==""?", ":"")}<span  className={"b detailBoxBold"+ nominationDetails && nominationDetails?.address?"display-block detailBoxBold":"display-none"}>{nominationDetails && nominationDetails?.address?nominationDetails?.address:""}</span></div>
                        </div><br/>

                        <label className="required mt-4">Contact info</label><br/>
                        <div>
                            {nominationDetails && nominationDetails.primaryContactNumber &&
                            <div className={"divspace detailBoxBold"}>{nominationDetails.primaryContactNumber}</div>
                            }
                            {nominationDetails && nominationDetails.email &&
                            <div className={"divspace detailBoxBold"}>{nominationDetails.email}</div>
                            }
                        </div><br/>
                      </div>
                    </div>
                  </div>
      
                  <div className="col-md-3 detailBox">
                      <label className="required">PF number</label><br/>
                      <div className={"detailBoxBold"}>{nominationDetails && nominationDetails.pfNumber!==undefined?nominationDetails.pfNumber:"-"}</div><br/> 
                      <label className="required mt-4">Membership Status</label><br/>
                      <div className={"detailBoxBold"}>{nominationDetails.membershipStatus==="A"?"ACTIVE":"-"}</div>
                  </div>

                  <div className="col-md-3 detailBox">
                      <label className="required">PAN # (in the system)</label><br/>
                      <div className={"detailBoxBold"}>{nominationDetails && nominationDetails.pan!==undefined?nominationDetails.pan:"-"}</div><br/>
                      <label className="required mt-4">Verified PAN number</label><br/>
                      <div className={"detailBoxBold"}>{nominationDetails && nominationDetails.verifiedpan!==undefined?nominationDetails.verifiedpan:"-"}</div>
                  </div>
                  {nominationDetails && nominationDetails.status!=="Withdraw" && globalState.state.isChiefElectoralOfficer===true && !globalState.state.withdrawNominations && !globalState.state.publishCandidateList &&
                  <div className="col-md-12 "> 
                    <button className="btn btn-primary  ml-3 btnRight" type="button" onClick={() =>onApproved()}>Approve</button>
                    <button className="btn btn-txt btnRight" type="button" onClick={() =>setRejectNominationClick(true)}>Reject</button>
                   </div>
                  }
                </div>
                </form>
                  
                  </div>
                </div>
              </div>
            </div>
            <NominationDetailsTable/>
        </div>
      </div> 
         {/** Modal Popup **/}
         <NominationRejectPopup
             rejectNomination={rejectNomination}
             rejectionNote={rejectionNote}
             setRejectionNote={setRejectionNote}
             setRejectNominationClick={setRejectNominationClick}
             onRejected={onRejected}
             showMandatoryValidation={showMandatoryValidation}
             setShowMandatoryValidation={setShowMandatoryValidation}
             buttonName={"Save"}
         />
    </div>);
}

export default NominationDetailsCard;