import React, { useContext } from "react";
import LoginCard from "../LoginCard";
import { LoginContext } from "./LoginContext";

const Login = () => {
  const LoginModel = useContext(LoginContext);
  return (
    <div>
      <LoginCard
        setPhoneNumber={LoginModel.setPhoneNumber}
        setOtp={LoginModel.setOtp}
        otpRequest={LoginModel.otpRequest}
        verifyOtp={LoginModel.verifyOtp}
        spinnerSignin={LoginModel.spinnerSignin}
        signinErrorMsg={LoginModel.signinErrorMsg}
        setSigninErrorMsg={LoginModel.setSigninErrorMsg}
        otp={LoginModel.otp}
        phoneNumber={LoginModel.phoneNumber}
      />
    </div>
  );
};

export default Login;
