import React from "react";
import Carousel from "react-bootstrap/Carousel";

import Candidate2 from "../assets/images/face2.jpg";
import Candidate3 from "../assets/images/face3.jpg";
import Candidate4 from "../assets/images/face6.jpg";
import Candidate5 from "../assets/images/face15.jpg";
import CrownIcon from "mdi-react/CrownIcon";
import StarIcon from "mdi-react/StarIcon";


const carouselCard = () => {
  return (
    <div>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="row">
            <div className="col-md-12 grid-margin">
              <div className="d-flex justify-content-between flex-wrap">
                <div className="d-flex align-items-center dashboard-header flex-wrap mb-3 mb-sm-0">
                  <h4 className="mr-4 mb-0 font-weight-bold">Results</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 grid-margin">
              <div className="card president-slid">
                <div className="card-body">
                  <Carousel>
                    <Carousel.Item>
                      <div className="d-flex align-items-start justify-content-between">
                        <p className="card-title1 flex-grow text-center mt-2">
                          <div className="president-hds">
                            <span>
                              <StarIcon size={12} className="text-muted mr-2" />
                              President
                              <StarIcon size={12} className="text-muted ml-2" />
                            </span>
                          </div>
                        </p>
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-lg-6 grid-margin grid-margin-md-0 ">
                          {/********************** Blue Card start ****************************/}

                          <div className="card blueCard">
                            <div className="card-bdy text-center">
                              <CrownIcon className="iconCrown" size={34} />
                              <div>
                                <img
                                  src={Candidate3}
                                  className="img-lg rounded-circle mb-2 mt-2"
                                  alt="profile"
                                />
                                <h4 className="txt-bold mt-2">Vivek Shekar</h4>
                                <p className="mb-0  text-small">
                                  PF#:1234512345
                                </p>
                                <p className="mb-0  text-small">
                                  9876510000/9876510001
                                </p>
                              </div>
                              <div className="mt-4 whiteBox">
                                <div className="row">
                                  <div className="col-6 border-rgt text-center">
                                    <p className=" mt-2 text-muted">
                                      Total Votes
                                    </p>
                                    <p className="numberTxt">1,200</p>
                                  </div>
                                  <div className="col-6 text-center">
                                    <p className=" mt-2 text-muted">Lead</p>
                                    <p className="numberTxt">50</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/********************** Blue Card End  ****************************/}

                        {/********************** Table List Start  ****************************/}

                        <div className="col-md-12 col-lg-6 grid-margin grid-margin-md-0 slide-pading-right">
                          <div className="card-body slide-pading-right">
                            <div className="president-hds">
                              <h4 className="card-title1 text-center text-muted">
                                OTHERS
                              </h4>
                            </div>
                            <div className="table-responsive">
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <td className="py-1 pl-0">
                                      <div className="d-flex align-items-center">
                                        <img src={Candidate2} alt="profile" />
                                        <div className="ml-3">
                                          <p className="mb-0 txt-bold">
                                            Sophia Brown
                                          </p>
                                          <p className="mb-0  text-small">
                                            PF#:1234512345
                                          </p>
                                          <p className="mb-0  text-small">
                                            9876510000/9876510001
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <span className="d-flex justify-content-end txt-bold">
                                        1200
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="py-1 pl-0">
                                      <div className="d-flex align-items-center">
                                        <img src={Candidate5} alt="profile" />
                                        <div className="ml-3">
                                          <p className="mb-0 txt-bold">
                                            Rachel Newton
                                          </p>
                                          <p className="mb-0  text-small">
                                            PF#:1234512345
                                          </p>
                                          <p className="mb-0  text-small">
                                            9876510000/9876510001
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <span className="d-flex justify-content-end txt-bold">
                                        200
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="py-1 pl-0">
                                      <div className="d-flex align-items-center">
                                        <img src={Candidate3} alt="profile" />
                                        <div className="ml-3">
                                          <p className="mb-0 txt-bold">
                                            Marcus Stevens
                                          </p>
                                          <p className="mb-0  text-small">
                                            PF#:1234512345
                                          </p>
                                          <p className="mb-0  text-small">
                                            9876510000/9876510001
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <span className="d-flex justify-content-end txt-bold">
                                        150
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="py-1 pl-0">
                                      <div className="d-flex align-items-center">
                                        <img src={Candidate4} alt="profile" />
                                        <div className="ml-3">
                                          <p className="mb-0 txt-bold">
                                            Theresa Becker
                                          </p>
                                          <p className="mb-0  text-small">
                                            PF#:1234512345
                                          </p>
                                          <p className="mb-0  text-small">
                                            9876510000/9876510001
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <span className="d-flex justify-content-end txt-bold">
                                        100
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/********************** Table List End  ****************************/}
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="d-flex align-items-start justify-content-between">
                        <p className="card-title1 flex-grow text-center mt-2">
                          <div className="president-hds">
                            <span>
                              <StarIcon size={12} className="text-muted mr-2" />
                              Vice President
                              <StarIcon size={12} className="text-muted ml-2" />
                            </span>
                          </div>
                        </p>
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-lg-6 grid-margin grid-margin-md-0">
                          {/********************** Blue Card Start  ****************************/}
                          <div className="scrollbar-primary blue-card-height">
                          <div className="bg-gray-slid">
                                <div className="d-flex w-100 justify-content-between slider-left">
                                <div className="blue-over-img">
                                <img
                                  src={Candidate3}
                                  className=""
                                  alt="profile"
                                />
                                <div className="crown-above">
                                <CrownIcon className="iconCrown" size={26} />
                                </div>
                                </div>
                                <div className="w-100 left-spc">
                                  <h4>Carmen McCoy</h4>
                                  <h6>PF#: 1234567</h6>
                                  <h6>9876545/90876546</h6>
                                  </div>
                                <div className="total-count">
                                  <span>Total Votes</span>
                                  <div>12,000</div>
                                  </div>
                                </div>
                            </div>

                            <div className="bg-gray-slid">
                                <div className="d-flex w-100 justify-content-between slider-left">
                                <div className="blue-over-img">
                                <img
                                  src={Candidate3}
                                  className=""
                                  alt="profile"
                                />
                                <div className="crown-above">
                                <CrownIcon className="iconCrown" size={26} />
                                </div>
                                </div>
                                <div className="w-100 left-spc">
                                  <h4>Carmen McCoy</h4>
                                  <h6>PF#: 1234567</h6>
                                  <h6>9876545/90876546</h6>
                                  </div>
                                <div className="total-count">
                                  <span>Total Votes</span>
                                  <div>12,000</div>
                                  </div>
                                </div>
                            </div>
                            <div className="bg-gray-slid">
                                <div className="d-flex w-100 justify-content-between slider-left">
                                <div className="blue-over-img">
                                <img
                                  src={Candidate3}
                                  className=""
                                  alt="profile"
                                />
                                <div className="crown-above">
                                <CrownIcon className="iconCrown" size={26} />
                                </div>
                                </div>
                                <div className="w-100 left-spc">
                                  <h4>Carmen McCoy</h4>
                                  <h6>PF#: 1234567</h6>
                                  <h6>9876545/90876546</h6>
                                  </div>
                                <div className="total-count">
                                  <span>Total Votes</span>
                                  <div>12,000</div>
                                  </div>
                                </div>
                            </div>                          <div className="bg-gray-slid">
                                <div className="d-flex w-100 justify-content-between slider-left">
                                <div className="blue-over-img">
                                <img
                                  src={Candidate3}
                                  className=""
                                  alt="profile"
                                />
                                <div className="crown-above">
                                <CrownIcon className="iconCrown" size={26} />
                                </div>
                                </div>
                                <div className="w-100 left-spc">
                                  <h4>Carmen McCoy</h4>
                                  <h6>PF#: 1234567</h6>
                                  <h6>9876545/90876546</h6>
                                  </div>
                                <div className="total-count">
                                  <span>Total Votes</span>
                                  <div>12,000</div>
                                  </div>
                                </div>
                            </div>
                            <div className="bg-gray-slid">
                                <div className="d-flex w-100 justify-content-between slider-left">
                                <div className="blue-over-img">
                                <img
                                  src={Candidate3}
                                  className=""
                                  alt="profile"
                                />
                                <div className="crown-above">
                                <CrownIcon className="iconCrown" size={26} />
                                </div>
                                </div>
                                <div className="w-100 left-spc">
                                  <h4>Carmen McCoy</h4>
                                  <h6>PF#: 1234567</h6>
                                  <h6>9876545/90876546</h6>
                                  </div>
                                <div className="total-count">
                                  <span>Total Votes</span>
                                  <div>12,000</div>
                                  </div>
                                </div>
                            </div>
                            <div className="bg-gray-slid">
                                <div className="d-flex w-100 justify-content-between slider-left">
                                <div className="blue-over-img">
                                <img
                                  src={Candidate3}
                                  className=""
                                  alt="profile"
                                />
                                <div className="crown-above">
                                <CrownIcon className="iconCrown" size={26} />
                                </div>
                                </div>
                                <div className="w-100 left-spc">
                                  <h4>Carmen McCoy</h4>
                                  <h6>PF#: 1234567</h6>
                                  <h6>9876545/90876546</h6>
                                  </div>
                                <div className="total-count">
                                  <span>Total Votes</span>
                                  <div>12,000</div>
                                  </div>
                                </div>
                            </div>
                          </div>
                        </div>

                        {/********************** Blue Card End  ****************************/}

                        {/********************** Table List Start  ****************************/}

                        <div className="col-md-12 col-lg-6 grid-margin grid-margin-md-0 slide-pading-right">
                          <div className="card-body slide-pading-right">
                            <div className="president-hds">
                              <h4 className="card-title1 text-center text-muted">
                                OTHERS
                              </h4>
                            </div>
                            <div className="table-responsive">
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <td className="py-1 pl-0">
                                      <div className="d-flex align-items-center">
                                        <img src={Candidate2} alt="profile" />
                                        <div className="ml-3">
                                          <p className="mb-0 txt-bold">
                                            Sophia Brown
                                          </p>
                                          <p className="mb-0  text-small">
                                            PF#:1234512345
                                          </p>
                                          <p className="mb-0  text-small">
                                            9876510000/9876510001
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <span className="d-flex justify-content-end txt-bold">
                                        1200
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="py-1 pl-0">
                                      <div className="d-flex align-items-center">
                                        <img src={Candidate5} alt="profile" />
                                        <div className="ml-3">
                                          <p className="mb-0 txt-bold">
                                            Rachel Newton
                                          </p>
                                          <p className="mb-0  text-small">
                                            PF#:1234512345
                                          </p>
                                          <p className="mb-0  text-small">
                                            9876510000/9876510001
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <span className="d-flex justify-content-end txt-bold">
                                        200
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="py-1 pl-0">
                                      <div className="d-flex align-items-center">
                                        <img src={Candidate3} alt="profile" />
                                        <div className="ml-3">
                                          <p className="mb-0 txt-bold">
                                            Marcus Stevens
                                          </p>
                                          <p className="mb-0  text-small">
                                            PF#:1234512345
                                          </p>
                                          <p className="mb-0  text-small">
                                            9876510000/9876510001
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <span className="d-flex justify-content-end txt-bold">
                                        150
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="py-1 pl-0">
                                      <div className="d-flex align-items-center">
                                        <img src={Candidate4} alt="profile" />
                                        <div className="ml-3">
                                          <p className="mb-0 txt-bold">
                                            Theresa Becker
                                          </p>
                                          <p className="mb-0  text-small">
                                            PF#:1234512345
                                          </p>
                                          <p className="mb-0  text-small">
                                            9876510000/9876510001
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <span className="d-flex justify-content-end txt-bold">
                                        100
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/********************** Table List Ends  ****************************/}
                    </Carousel.Item>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default carouselCard;