import React from "react";
import SettingsCard from "../components/Settings/SettingsContainer";
import Sidebar from "../components/Sidebar";
import {SettingsContextProvider} from "../components/Settings/SettingsContainerContext";

const SettingsPage = () => {
  return (
    <div>
      <SettingsContextProvider>
        <Sidebar />
        <SettingsCard />
      </SettingsContextProvider>
    </div>
  );
};

export default SettingsPage;
