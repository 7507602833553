import React, { memo } from "react";
import Modal from "react-bootstrap/Modal";
//import DatePicker from "react-date-picker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/Button";
import { useEffect } from "react";

const PopupA = ({
  handleClose,
  title,
  show,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  dropListTitle,
  inputValue,
  descriptionTitle,
  description,
  saveData,
  selectedItem,
  setDescription,
  onEndDatechange,
  onStartDatechange,
  stageNameValidation,
  setStageNameValidation,
  stageDescriptionValidation,
  setStageDescriptionValidation,
  setShow,
  editItem
}) => {
  // useEffect(() => {
  // }, [show]);
  return (
    <Modal show={show}>
      <Modal.Header closeButton onClick={()=>setShow(false)}>
        <Modal.Title>
          <h5>{title}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="form-group">
            <label>
              {dropListTitle} <span className="reqStar">*</span>
            </label>
            {/* <select
              className="form-control selectdec"
              onChange={(e) => selectDropList(e.target.value)}
            > */}
              <input 
              onChange={(e) => inputValue(e.target.value)} 
              onFocus={()=>setStageNameValidation(false)} 
              className={"form-control selectcontrol " + (stageNameValidation?"border-validation":"")}
              value={selectedItem} />
          </div>
          <div className="form-group">
            <label>
              {descriptionTitle} <span className="reqStar">*</span>
            </label>
            <textarea
              className={"form-control inputDec " + ( stageDescriptionValidation===true?"border-validation":"")}
              id="message-text"
              rows="5"
              placeholder="Decription here"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              onFocus={()=>setStageDescriptionValidation(false)}
            ></textarea>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label className="lbl-text">Start Date & time</label>
              <div className="input-group">
                <DatePicker
                  timeInputLabel="Time:"
                  selected={new Date(startDate)}
                  placeholderText="DD/MM/YYYY HH:mm"
                  dateFormat="dd/MM/yyyy HH:mm"
                  className={"schedule-datetime-tab"}
                  onChange={onStartDatechange}
                  showTimeInput
              />
              </div>
            </div>
            <div className="col-md-6 modalInput">
              <label className="lbl-text">End date & time</label>
              <div className="input-group">
              <DatePicker
                  timeInputLabel="Time:"
                  selected={new Date(endDate)}
                  placeholderText="DD/MM/YYYY HH:mm"
                  dateFormat="dd/MM/yyyy HH:mm"
                  className="schedule-datetime-tab"
                  onChange={onEndDatechange}
                  showTimeInput
              />
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <p className="text-primary btn mt-2" onClick={()=>setShow(false)}>
          Close
        </p>
        <Button
          variant="primary"
          onClick={() => {
            saveData();
          }}
        >
          {editItem?"Update":"Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(PopupA);
