import React from 'react';
import NominationDetailsCard from '../components/NominationDetails/NominationDetailsCard';
import Sidebar from '../components/Sidebar';
import { NominationDetailsContextProvider } from "../components/NominationDetails/NominationDetailsContext";

const NominationDetailPage = () => {
    return <div>
      <NominationDetailsContextProvider>
        <Sidebar />
        <NominationDetailsCard />
      </NominationDetailsContextProvider>     
    </div>;
}

export default NominationDetailPage;