import React, { useContext } from "react";
import SettingsCard from "../SettingsCard";
import { SettingsContext } from "./SettingsContainerContext";
import PopupD from "../PopupD";
import settings from "../../constants/settings";
import {store} from "../../store/store";

const SettingsContainer = () => {
  const globalState = useContext(store);
  const status = false;
  const settingsModel = useContext(SettingsContext);
  const { settingsList, 
    setSettingsList, 
    enableSettings,
    showPopup,
    setShowPopup,
    isSuccess,
    onConfirmClick,
    message,
  } = settingsModel;
  return (
    <div>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="row">
            <div className="col-md-12 grid-margin">
              <div className="d-flex justify-content-between flex-wrap">
                <div className="d-flex align-items-center dashboard-header flex-wrap mb-3 mb-sm-0">
                  <h4 className="mr-4 mb-0 font-weight-bold">Settings</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {settingsList.length > 0 &&
              settingsList?.map((item) => {
                const isDisabled=(title)=>{
                  switch (title) {
                    case settings.DECLARE_RESULT:
                      if(globalState.state.lockResult && globalState.state.isChiefElectoralOfficer){
                        return false;
                      }
                      else{
                        return true;
                      }
                      break;
                    case settings.ONBOARD_NEW_COMMITTEE:
                      if(globalState.state.declareResult && globalState.state.isChiefElectoralOfficer){
                        return false;
                      }
                      else{
                        return true;
                      }
                      break;
                    case settings.VOTING:
                        if(globalState.state.publishCandidateList && globalState.state.isChiefElectoralOfficer){
                          return false;
                        }
                        else{
                          return true;
                        }
                        break;
                    case settings.MEMBER_PROFILE_EDIT:
                        if((globalState.state.onboardEnableCount || item.isEnabled) && globalState.state.isChiefElectoralOfficer){
                          return false;
                        }
                        else{
                          return true;
                        }
                        break;  
                    default:
                      if((item.buttonStatus!== undefined && item.buttonStatus===0) || !globalState.state.isChiefElectoralOfficer){
                        return true;
                      }
                      else{
                        return false;
                      }
                      break;
                  }
                }
                return (
                  <SettingsCard
                    buttonText="testing"
                    title={item.title}
                    enabled={item.isEnabled}
                    buttonAction={enableSettings}
                    id={item._id}
                    disableBtn={isDisabled(item.title)}
                    enableCount={item.enabledCount}
                    buttonStatus={item.buttonStatus}
                  />
                );
              })}
          </div>
        </div>
      </div>
      <PopupD
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        isSuccess={isSuccess}
        onConfirmClick={onConfirmClick}
        message={message}
        isWarning={false}
      />
    </div>
  );
};

export default SettingsContainer;
