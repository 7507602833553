import React, { useContext } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory,{PaginationTotalStandalone} from "react-bootstrap-table2-paginator";
import { NominationContext } from "./NominationContext";
import NominationExport from "./NominationExport";
import Switch from "react-switch";
import { store } from "../../store/store";
import NominationRejectPopup from '../NominationDetails/NominationRejectPopup';

const NominationCard = () => {
  const globalState = useContext(store);
  const nominationModel = useContext(NominationContext);
  const {
    nominations,
    nominationsfiltered,
    columnData,
    positions,
    status,
    selectedPosition,
    selectedStatus,
    setSelectPosition,
    setSelectStatus,
    onSelectStatus,
    onSelectPosition,
    setTotalPages,
    setCurrentPage,
    setSizePerPage,
    sizePerPage,
    currentPage,
    totalPages,
    getNominationsFromApi,
    totalRows, 
    setTotalRows,
    setMultipleNominationSwitch,
    multipleNominationSwitch,
    onRejectAllClick,
    rejectNomination,
    rejectionNote,
    setRejectionNote,
    setRejectNominationClick,
    onRejected,
    showMandatoryValidation,
    setShowMandatoryValidation,
    showRejectAll
  } = nominationModel;
  const { SearchBar } = Search;

  const pagination = paginationFactory({
    page: currentPage,
    sizePerPage: localStorage.getItem('pageSize'),
    lastPageText: "Last",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    totalSize: totalRows,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      setSizePerPage(sizePerPage);
      setCurrentPage(page);
      // getNominationsFromApi(page, sizePerPage);

    },
    onSizePerPageChange: function (sizePerPage, page) {
      setSizePerPage(sizePerPage);
      setCurrentPage(page);
      localStorage.setItem("pageSize", sizePerPage);
      // getNominationsFromApi(page, sizePerPage);
    },
  });

  return (
    <div>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="row">
            <div className="col-md-12 grid-margin">
              <div className="d-flex justify-content-between flex-wrap">
                <div className="d-flex align-items-center dashboard-header flex-wrap mb-3 mb-sm-0">
                  <h4 className="mr-4 mb-0 font-weight-bold">Nominations</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-start justify-content-between">
                    <p className="card-title flex-grow">Nomination Submitted</p>
                    {globalState.state.isChiefElectoralOfficer===true && showRejectAll===true &&
                    <label htmlFor="material-switch" className="switch">
                      <span className="lblSwitch">View Multiple Nominations</span>
                      <Switch
                        checked={multipleNominationSwitch}
                        onChange={()=>(setMultipleNominationSwitch(!multipleNominationSwitch),setCurrentPage(1))}
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={48}
                        className="react-switch"
                        id="material-switch"
                      />
                    </label>
                    }
                    {!multipleNominationSwitch &&
                    <NominationExport/>
                    }
                    {multipleNominationSwitch && showRejectAll===true &&
                    <button disabled={nominations.length>0?false :true} onClick={()=>onRejectAllClick()} className="btn btn-danger pull-right">Reject All</button>
                    }
                  </div>
                  <ToolkitProvider
                    keyField="id"
                    data={nominationsfiltered}
                    columns={columnData}
                    search
                  >
                    {(props) => (
                      <div>
                        {!multipleNominationSwitch &&
                        <div className="d-flex align-items-center justify-content-between mt-3">
                          <div className="d-flex align-items-center justify-content-between">
                            <span>
                              <select className="form-control nomini-left"  value={selectedPosition ? selectedPosition : ''} onChange={({ target: { value } }) =>onSelectPosition(value)}>
                                <option value="">All Position</option>
                                {positions && positions.map((e, key) => {
                                    return <option key={key} value={e._id}>{e.name}</option>;
                                })}
                              </select>
                            </span>

                            <span className="ml-3">
                              <select className="form-control nomini-left" value={selectedStatus ? selectedStatus : ''} onChange={({ target: { value } }) =>onSelectStatus(value)}>
                                <option value="">All Status</option>
                                {status && status.map((e, key) => {
                                    return <option key={key} value={e.value}>{e.name==="Withdraw"?"Withdrawn":e.name}</option>;
                                })}
                              </select>
                            </span>
                          </div>
                          <span className="srch-box">
                            <label className="mr-2">Search:</label>
                            <SearchBar
                              {...props.searchProps}
                              className="inpt-box"
                            />
                          </span>
                        </div>
                    }

                        <BootstrapTable
                          {...props.baseProps}
                          pagination={pagination}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NominationRejectPopup
             rejectNomination={rejectNomination}
             rejectionNote={rejectionNote}
             setRejectionNote={setRejectionNote}
             setRejectNominationClick={setRejectNominationClick}
             onRejected={onRejected}
             showMandatoryValidation={showMandatoryValidation}
             setShowMandatoryValidation={setShowMandatoryValidation}
             buttonName={"Confirm"}
         />
    </div>
  );
}

export default NominationCard;
