import React from "react";
import "./Component.css";
import { NavLink } from "react-router-dom";
import HumanGreetingIcon from "mdi-react/HumanGreetingIcon";
import CalendarIcon from "mdi-react/CalendarIcon";
import AccountCheckIcon from "mdi-react/AccountCheckIcon";
import ClipboardAccountIcon from "mdi-react/ClipboardAccountIcon";
import MessageIcon from "mdi-react/MessageIcon";
import CogIcon from "mdi-react/CogIcon";
import LogoutIcon from "mdi-react/LogoutIcon";
import AccountIcon from "mdi-react/AccountIcon";
import StatIcon from "mdi-react/CheckbookIcon"
import { useHistory } from "react-router-dom";

const Sidebar = () => {
  const history = useHistory();

  return (
    <div>
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="sidebar-profile">
          <div className="d-flex align-items-center justify-content-between">
            <span>
              <AccountIcon size={24} />
            </span>

            <div className="profile-desc">
              <p className="name mb-0">Administrator</p>
            </div>
          </div>
        </div>

        <ul className="nav">
          <li className="nav-item">
            <NavLink className="nav-link" to="/position">
              <ClipboardAccountIcon className="menu-icon" size={16} />
              <span className="menu-title">Position</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/settings">
              <CogIcon className="menu-icon" size={16} />
              <span className="menu-title">Settings</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="nomination">
              <AccountCheckIcon className="menu-icon" size={16} />
              <span className="menu-title">Nominations</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/stats">
              <StatIcon className="menu-icon" size={16} />
              <span className="menu-title">Stats</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/result">
              <HumanGreetingIcon className="menu-icon" size={16} />
              <span className="menu-title">Result</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/schedule">
              <CalendarIcon className="menu-icon" size={16} />
              <span className="menu-title">Schedule</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/notification">
              <MessageIcon className="menu-icon" size={16} />
              <span className="menu-title">Messages</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/member">
              <AccountIcon className="menu-icon" size={16} />
              <span className="menu-title">Edit Member Profile</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/" onClick={() => window.location.href='/'}>
              <LogoutIcon className="menu-icon" size={16} />
              <span className="menu-title" >Logout</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
