import React, { memo } from "react";
import Modal from "react-bootstrap/Modal";
//import DatePicker from "react-date-picker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/Button";
import { useEffect } from "react";

const PopupC = ({
  title,
  show,
  dropListTitle,
  inputValue,
  descriptionTitle,
  description,
  selectedItem,
  setDescription,
  titleValidation,
  setTitleValidation,
  descriptionValidation,
  setDescriptionValidation,
  setShow,
  onTargetZoneSelect,
  targetZone,
  zoneList,
  scheduleDate,
  onScheduleDatechange,
  scheduleNotification,
  sendNotification,
  setZoneValidation,
  zoneValidation,
  setDateValidation,
  dateValidation
}) => {
  // useEffect(() => {
  // }, [show]);
  return (
    <Modal show={show}>
      <Modal.Header closeButton onClick={()=>setShow(false)}>
        <Modal.Title>
          <h5>{title}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="form-group">
            <label>
              {dropListTitle} <span className="reqStar">*</span>
            </label>
              <input 
              onChange={(e) => inputValue(e.target.value)} 
              onFocus={()=>setTitleValidation(false)} 
              className={"form-control selectcontrol " + (titleValidation?"border-validation":"")}
              value={selectedItem} />
          </div>
          <div className="form-group">
            <label>
              {descriptionTitle} <span className="reqStar">*</span>
            </label>
            <textarea
              className={"form-control inputDec " + ( descriptionValidation===true?"border-validation":"")}
              id="message-text"
              rows="10"
              placeholder="Decription here"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              onFocus={()=>setDescriptionValidation(false)}
            ></textarea>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label className="lbl-text">{"Target Zone"}<span className="reqStar">*</span></label>
              <div className="input-group">
              <select onChange={(e) => onTargetZoneSelect(e.target.value)} value={targetZone} onFocus={()=>setZoneValidation(false)} className={"form-control inputDec " + ( zoneValidation===true?"border-validation":"")}>
                  <option value="0">Select</option>
                  {zoneList.map((item, index) => {
                    return <option key={index} value={item._id}>{item.name}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-6 modalInput">
              <label className="lbl-text">{"Schedule"}<span className="reqStar">*</span></label>
              <div className="input-group">
              <DatePicker
                  timeInputLabel="Time:"
                  selected={new Date(scheduleDate)}
                  placeholderText="DD/MM/YYYY HH:mm"
                  dateFormat="dd/MM/yyyy HH:mm"
                  className={"schedule-datetime-tab inputDec "+ ( dateValidation===true?"border-validation":"")}
                  onChange={onScheduleDatechange}
                  onFocus={()=>setDateValidation(false)}
                  showTimeInput
                  minDate={new Date()}
              />
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <p className="text-primary btn mt-2 cursotpointer" onClick={()=>setShow(false)}>
          Cancel
        </p>
        <Button
          className="btn-outline-primary"
          variant="mt-2"
          onClick={() => {
            scheduleNotification();
          }}
        >
            {"Schedule"}
        </Button>

        <Button
          variant="primary"
          onClick={() => {
            sendNotification();
          }}
        >
          {"Send Now"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(PopupC);
