export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const TOKEN = "TOKEN";
export const IS_CHIEF_ELECTORAL_OFFICER = "IS_CHIEF_ELECTORAL_OFFICER";
export const USER = "USER";
export const WITHDRAW_NOMINATIONS = "WITHDRAW_NOMINATIONS";
export const DECLARE_RESULT = "DECLARE_RESULT";
export const LOCK_RESULTS = "LOCK_RESULTS";
export const PUBLISH_CANDIDATE_LIST = "PUBLISH_CANDIDATE_LIST";
export const VOTING = "VOTING";
export const VOTING_ENABLECOUNT = "VOTING_ENABLECOUNT";
export const ONBOARD_NEW_COMMITTEE = "ONBOARD_NEW_COMMITTEE";
export const ONBOARD_NEW_COMMITTEE_ENABLECOUNT = "ONBOARD_NEW_COMMITTEE_ENABLECOUNT";