import React, { useState, useContext, memo } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import { PositionContext } from "./PositionContext";
import ContainerForTable from "../ContainerForTable";
import PopupB from "../PopupB";
import PopupD from "../PopupD";

const PositionCard = () => {
  const positionModel = useContext(PositionContext);
  const {
    positions,
    columnData,
    show,
    positionName,
    editButtonClick,
    addNewPosition,
    numberOfPositions,
    targetNomineeZone,
    targetVotersZone,

    setColumnData,
    setShow,
    handleClose,
    handleShow,
    saveData,
    zoneList,
    onPositionCountSelect,
    onTargetVotersZoneSelect,
    onTargetNomineeZoneSelect,
    onPositionNameChange,
    editItem,
    positionNameValidation,
    numberOfPositionsValidation,
    setPositionNameValidation,
    setNumberOfPositionsValidation,
    gender,
    setGender,
    showPopup,
    setShowPopup,
    isSuccess,
    onConfirmClick,
    message,
    setTargetVotersZoneValidation,
    setTargetNomineeZoneValidation,
    targetVotersZoneValidation,
    targetNomineeZoneValidation
  } = positionModel;

  const { SearchBar } = Search;

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: "Last",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });

  const firstDropData = ["select1", "select2", "select3"];
  const zoneA = ["select1", "select2", "select3"];
  const zoneB = ["select1", "select2", "select3"];
  const [stageName, setStageName] = useState("");
  return (
    <div>
      <ContainerForTable
        title={"Positions"}
        handleShow={handleShow}
        products={positions}
        columns={columnData}
        SearchBar={SearchBar}
        pagination={pagination}
        buttonText={"Add new position"}
      />

      <PopupB
        show={show}
        title={editItem?"Edit Position":"Add New Position"}
        inputValue={setStageName}
        inputValueTitle="Position Name"
        handleClose={handleClose}
        firstDropDown={firstDropData}
        firstDropDownTitle={"No.of Positions"}
        dropDownA={zoneA}
        dropDownB={zoneB}
        dropDownATitle={"Target Nominee Zone"}
        dropDownBTitle={"Target Voters Zone"}
        subTitle={"Commission members"}
        zoneList={zoneList}
        onPositionCountSelect={onPositionCountSelect}
        onTargetVotersZoneSelect={onTargetVotersZoneSelect}
        onTargetNomineeZoneSelect={onTargetNomineeZoneSelect}
        onPositionNameChange={onPositionNameChange}
        positionName={positionName}
        numberOfPositions={numberOfPositions}
        targetNomineeZone={targetNomineeZone}
        targetVotersZone={targetVotersZone}
        saveData={saveData}
        positionNameValidation={positionNameValidation}
        numberOfPositionsValidation={numberOfPositionsValidation}
        setPositionNameValidation={setPositionNameValidation}
        setNumberOfPositionsValidation={setNumberOfPositionsValidation}
        firstDropDownTitleGender={"Gender"}
        gender={gender}
        setGender={setGender}
        editItem={editItem}
        setTargetVotersZoneValidation={setTargetVotersZoneValidation}
        setTargetNomineeZoneValidation={setTargetNomineeZoneValidation}
        targetVotersZoneValidation={targetVotersZoneValidation}
        targetNomineeZoneValidation={targetNomineeZoneValidation}
      />
      <PopupD
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        isSuccess={isSuccess}
        onConfirmClick={onConfirmClick}
        message={message}
        isWarning={false}
      />
    </div>
  );
};

export default memo(PositionCard);
