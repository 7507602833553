import React from "react";
import InformationIcon from "mdi-react/InformationIcon";
import ReactTooltip from "react-tooltip";
import CheckIcon from "mdi-react/CheckIcon";
import election_sign_off from "../assets/images/election_sign_off.png";
import declare_results from "../assets/images/declare_results.png";
import lock_results from "../assets/images/lock_results.png";
import member_profile_edit from "../assets/images/member_profile_edit.png";
import onboard_new_committee from "../assets/images/onboard_new_committee.png";
import publish_vote_count from "../assets/images/publish_vote_count.png";
import submit_nominations from "../assets/images/submit_nominations.png";
import voting from "../assets/images/voting.png";
import withdraw_nominations from "../assets/images/withdraw_nominations.png";
import settings from "../constants/settings";
import AlreadyDone from "../assets/images/check.png";

const SettingsCard = ({
  buttonText,
  title,
  icon,
  buttonAction,
  enabled,
  id,
  disableBtn,
  enableCount,
  buttonStatus,
}) => {
  let note = enabled
    ? title.trim() === settings.DECLARE_RESULTS.trim()
      ? settings.DECLARE_RESULTS_NOTE
      : title === settings.LOCK_RESULTS
      ? settings.LOCK_RESULTS_NOTE
      : title.trim() === settings.ONBOARD_NEW_COMMITTEE
      ? settings.ONBOARD_NEW_COMMITTEE_NOTE
      : title === settings.SUBMIT_NOMINATIONS
      ? settings.SUBMIT_NOMINATIONS_NOTE
      : title === settings.VOTING
      ? settings.VOTING_NOTE
      : title === settings.WITHDRAW_NOMINATIONS
      ? settings.WITHDRAW_NOMINATIONS_NOTE
      : title.trim() === settings.DECLARE_RESULT.trim()
      ? settings.DECLARE_RESULTS_NOTE
      : title.trim() === settings.PUBLISH_CANDIDATE_LIST.trim()
      ? settings.PUBLISH_CANDIDATE_LIST_NOTE
      : ""
    : title.trim() === settings.MEMBER_PROFILE_EDIT
    ? settings.MEMBER_PROFILE_EDIT_NOTE
    : "";
  return (
    <div
      className="col-md-3 grid-margin grid-margin-md-0 stretch-card padbottom"
      id={id}
    >
      <div className="card">
        {true && (
          <div style={{ minHeight: 25 }}>
            {false && (
              <>
                <ReactTooltip />
                <InformationIcon
                  className="info-over text-muted"
                  size={16}
                  data-tip={title}
                />
              </>
            )}
          </div>
        )}

        <div className="card-body1 text-center">
          {title && (
            <div>
              <img
                src={
                  title.trim() === settings.ELECTION_SIGNOFF
                    ? election_sign_off
                    : title.toLowerCase().trim() ===
                      settings.DECLARE_RESULTS.toLowerCase().trim()
                    ? declare_results
                    : title === settings.LOCK_RESULTS
                    ? lock_results
                    : title.trim() === settings.ONBOARD_NEW_COMMITTEE
                    ? onboard_new_committee
                    : title === settings.PUBLISH_CANDIDATE_LIST
                    ? publish_vote_count
                    : title === settings.SUBMIT_NOMINATIONS
                    ? submit_nominations
                    : title === settings.VOTING
                    ? voting
                    : title === settings.WITHDRAW_NOMINATIONS
                    ? withdraw_nominations
                    : title.toLowerCase().trim() ===
                      settings.DECLARE_RESULT.toLowerCase().trim()
                    ? declare_results
                    : member_profile_edit
                }
                alt=""
              />
              <div>
                <p className={enabled ? "b-0 font-weight-bold" : "b-0"}>
                  {title}
                </p>
              </div>
            </div>
          )}
          {(((enableCount === 1 && buttonStatus === 1 && (settings.LOCK_RESULTS===title.trim() && !enabled)) ||
            (enableCount === 1 && buttonStatus === 1 && (settings.PUBLISH_CANDIDATE_LIST===title.trim() && !enabled)) ||
            (enableCount === 1 && buttonStatus === 1 && settings.LOCK_RESULTS!==title.trim()&& settings.PUBLISH_CANDIDATE_LIST!==title.trim())||
            (enableCount === 0 && buttonStatus === 1) ||
            (enableCount === 0 && buttonStatus === 0))) &&  (
            <button
              disabled={disableBtn}
              className={
                "btn btn-md mt-3 mb-4 " +
                (enabled ? "btn-primary " : "btn-outline-primary pl-4 pr-4 ") +
                (!enabled && title.trim() === settings.MEMBER_PROFILE_EDIT
                  ? "warningButton"
                  : "")
              }
              onClick={() => buttonAction(id, enabled, title.trim())}
            >
              {enabled && <CheckIcon size={16} className="mr-1" />}
              {enabled ? "Enabled" : "Enable"}
            </button>
          )}
          {((enableCount === 1 && buttonStatus === 0) || (settings.LOCK_RESULTS===title.trim() && enabled) || (settings.PUBLISH_CANDIDATE_LIST===title.trim() && enabled)) && (
            <img src={AlreadyDone} className="checkimg"></img>
          )}

          <div>
            <div className="settingsNote">{note}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsCard;
