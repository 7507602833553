const Icons ={
successTick:<svg xmlns="http://www.w3.org/2000/svg" width="108" height="108" viewBox="0 0 108 108">
<g id="successTick" transform="translate(-911 -358)">
  <g id="Ellipse_598" data-name="Ellipse 598" transform="translate(911 358)" fill="#fff" stroke="#13d570" stroke-width="5">
    <circle cx="54" cy="54" r="54" stroke="none"/>
    <circle cx="54" cy="54" r="51.5" fill="none"/>
  </g>
  <path id="Path_4994" data-name="Path 4994" d="M7664-58l16,13,30-34" transform="translate(-6721.5 474.5)" fill="none" stroke="#13d570" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5"/>
</g>
</svg>,
warning:<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 32 28">
<g id="warning" transform="translate(-730 -440)">
  <g id="Polygon_4" data-name="Polygon 4" transform="translate(730 440)" fill="#fff">
    <path d="M 26.83045768737793 26.49999618530273 L 5.169538021087646 26.49999618530273 C 4.389908313751221 26.49999618530273 4.002528190612793 25.97752571105957 3.872168064117432 25.75288581848145 C 3.741808176040649 25.52825736999512 3.480368137359619 24.93270683288574 3.867168188095093 24.25579643249512 L 14.69762802124023 5.302486896514893 C 15.08742809295654 4.620336532592773 15.73826789855957 4.546696662902832 15.99999809265137 4.546696662902832 C 16.26172828674316 4.546696662902832 16.91256904602051 4.620336532592773 17.3023681640625 5.302486896514893 L 28.13282775878906 24.25579643249512 C 28.51962852478027 24.93270683288574 28.25818824768066 25.52825736999512 28.12782859802246 25.75288581848145 C 27.99746894836426 25.97752571105957 27.61008834838867 26.49999618530273 26.83045768737793 26.49999618530273 Z" stroke="none"/>
    <path d="M 15.99999809265137 6.046697616577148 L 5.169538497924805 24.99999618530273 L 26.83045768737793 25.00000762939453 L 15.99999809265137 6.046697616577148 M 15.99999809265137 3.046697616577148 C 17.01444244384766 3.046697616577148 18.02888870239258 3.550556182861328 18.60472869873047 4.558277130126953 L 29.43518829345703 23.51158714294434 C 30.57802772521973 25.51155662536621 29.1339282989502 27.99999618530273 26.83045768737793 27.99999618530273 L 5.169538497924805 27.99999618530273 C 2.866067886352539 27.99999618530273 1.421968460083008 25.51155662536621 2.564807891845703 23.51158714294434 L 13.39526748657227 4.558277130126953 C 13.97110748291016 3.550556182861328 14.98555374145508 3.046697616577148 15.99999809265137 3.046697616577148 Z" stroke="none" fill="#ef5632"/>
  </g>
  <line id="Line_117" data-name="Line 117" y2="5.419" transform="translate(745.806 453.097)" fill="none" stroke="#ef5632" strokeLinecap="round" strokeWidth="3"/>
  <line id="Line_118" data-name="Line 118" transform="translate(745.806 462.129)" fill="none" stroke="#ef5632" strokeLinecap="round" strokeWidth="3"/>
</g>
</svg>
}
export default Icons;   