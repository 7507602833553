import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import API from "../../api/api";
import PencilIcon from "mdi-react/PencilIcon";
import DeleteIcon from "mdi-react/DeleteIcon";
import Moment from 'react-moment';
import { store } from "../../store/store";

export const NotificationContext = createContext();

const columns = [
  {
    dataField: "message",
    text: "Message info",
    sort: true,
    hidden: true,
    headerClasses: 'tableHeader',
    style: {
      maxWidth: '600px',
      whiteSpace: 'pre-line',
      overflowWrap: 'anywhere'
    }
  },
  {
    dataField: "title",
    text: "Message info",
    sort: true,
    formatter: (cellContent, row) => (
      <div>
        {row.title &&
          <div className="font-weight-bold">{row.title}</div >
        }
        {row.message &&
          <div>{row.message}</div >
        }
      </div>
    ),
    headerClasses: 'tableHeader'
  },
  {
    dataField: "targetZone.name",
    text: "Target zone",
    sort: true,
    headerClasses: 'tableHeader'
  },
  {
    dataField: "createdAt",
    text: "Date/Time",
    sort: true,
    formatter: (cellContent, row) => (
      <div>
        {row.createdAt &&
          <Moment date={new Date(row.createdAt)} format="DD/MM/YYYY HH:mm"></Moment >
        }
      </div>
    ),
    headerClasses: 'tableHeader'
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    headerClasses: 'tableHeader'
  },
  {
    dataField: "action",
    text: "Action",
    headerClasses: 'tableHeader'
  },
];

export const NotificationContextProvider = ({ children }) => {
  const globalState = useContext(store);
  const [notifications, setNotifications] = useState([]);
  const [editButtonClick, setEditButtonClick] = useState(false);
  const [columnData, setColumnData] = useState(columns);
  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [notificationTitle, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [titleValidation, setTitleValidation] = useState(false);
  const [descriptionValidation, setDescriptionValidation] = useState(false);
  const [targetZone, setTargetZone] = useState("0");
  const [zoneList, setZoneList] = useState([]);
  const [scheduleDate, setScheduleDate] = useState(new Date());
  const [zoneValidation, setZoneValidation] = useState(false);
  const [dateValidation, setDateValidation] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [deletenotificationId, setDeletenotificationId] = useState("");
  const [messageData, setMessageData] = useState("");
  const [columListData, setColumListData] = useState([]);
  const handleShow = () => {
    setShow(true);
    setZoneValidation(false);
    setDateValidation(false);
    setDescriptionValidation(false);
    setTitleValidation(false);
  };

  const resetButtonClicks = () => {
    setEditButtonClick(false);
  };

  const clearTheInputData = () => {
    setTitle("");
    setDescription("");
    setScheduleDate(new Date());
    setTargetZone("");
    setZoneValidation(false);
    setDateValidation(false);
    setDescriptionValidation(false);
    setTitleValidation(false);
    setSelectedId("");
  };

  const editNotificationItem = (item) => {
    setTitle(item.title);
    setDescription(item.message);
    setScheduleDate(new Date(item.scheduledAt));
    setTargetZone(item.targetZone ? item.targetZone._id : "");
    setSelectedId(item._id);
    setShow(true);
  };

  const changeObjStructure = async (data) => {
    const actionIncluded = await data.map((item) => ({
      ...item,
      action: (
        <div>
          {item.status !== "Sent" &&
            <div className="d-flex align-items-center ml-3">
              <div className="icon-square-sm-success hover-cursor align-items-center d-flex justify-content-center">
                <PencilIcon
                  size={16}
                  className="text-success"
                  onClick={() => editNotificationItem(item)}
                />
              </div>
              <div className="icon-square-sm-danger hover-cursor align-items-center d-flex justify-content-center ml-4">
                <DeleteIcon
                  size={16}
                  className="text-danger"
                  onClick={() => deleteNotificationsClick(item._id)}
                />
              </div>
            </div>
          }
        </div>
      ),
    }));
    return actionIncluded;
  };

  const getNotificationsFromApi = async () => {
    API.getNotifications(globalState.state.token)
      .then(async (response) => {
        const data = await changeObjStructure(response.data.notification_list);
        setNotifications(data);
      })
      .catch((e) => {
        console.log("error of commision list", e);
      });
  };

  const onTargetZoneSelect = (zone) => {
    setTargetZone(zone);
  }
  const onScheduleDatechange = (date) => {
    setScheduleDate(date);
  }
  const onConfirmClick = async () => {
    try {
      await API.deleteNotifications(deletenotificationId, globalState.state.token);
      getNotificationsFromApi();
      setShowPopup(false);
      setDeletenotificationId("");
      setMessage("");
    } catch (err) {
      console.log("add shedule error", err);
    }
  }
  const deleteNotificationsClick = (id) => {
    setDeletenotificationId(id);
    setMessage("Delete selected notification, Are you sure?");
    setShowPopup(true);
  }
  const validateData = () => {
    if (targetZone === "") {
      setZoneValidation(true);
    }
    if (description === "") {
      setDescriptionValidation(true);
    }
    if (notificationTitle === "") {
      setTitleValidation(true);
    }
    if (scheduleDate === "") {
      setDateValidation(true);
    }
  }
  const scheduleNotification = () => {
    validateData();
    if (targetZone !== "" && description !== "" && notificationTitle !== "" && scheduleDate !== "") {
      SaveNotificationApi("Scheduled")
    }
  }
  const sendNotification = () => {
    validateData();
    if (targetZone !== "0" && description !== "" && notificationTitle !== "" && scheduleDate !== "") {
      SaveNotificationApi("Sent")
    }
  }

  const fetchZoneList = () => {
    API.getZoneList(globalState.state.token)
      .then(async (response) => {
        setZoneList(response.data.zone_list);
      })
      .catch((e) => {
        console.log("error of commision list", e);
      });
  }

  const SaveNotificationApi = async (status) => {
    try {
      await API.updateNotifications(notificationTitle, description, targetZone, scheduleDate, status, selectedId, globalState.state.token);
      getNotificationsFromApi();
      clearTheInputData();
      resetButtonClicks();
      setShow(false);
    } catch (err) {
      console.log("add shedule error", err);
    }
  }

  const sendMessage = () => {
    API.getElectionToken(globalState.state.user.phone).then((response) => {
      API.sedMessage(response.data.token, messageData).then(() => { alert("Message has been sent."); }).catch((e) => {
        alert("Message sending failed.");
        console.log("message error")
      });
    }).catch((e) => {
      console.log("Token error")
    });
  }

  useEffect(async () => {
    getNotificationsFromApi();
    fetchZoneList();
    localStorage.setItem("statusFilter", "");
    localStorage.setItem("positionFilter", "");
  }, []);

  const providerValue = {
    notifications,
    columnData,
    show,
    editButtonClick,
    setNotifications,
    setColumnData,
    setShow,
    handleShow,
    setEditButtonClick,
    clearTheInputData,
    notifications,
    columnData,
    setTitle,
    notificationTitle,
    description,
    setDescription,
    titleValidation,
    setTitleValidation,
    descriptionValidation,
    setDescriptionValidation,
    onTargetZoneSelect,
    targetZone,
    zoneList,
    scheduleDate,
    onScheduleDatechange,
    scheduleNotification,
    sendNotification,
    setZoneValidation,
    zoneValidation,
    setDateValidation,
    dateValidation,
    showPopup,
    setShowPopup,
    isSuccess,
    onConfirmClick,
    message,
    setMessageData,
    columListData,
    sendMessage,
    messageData
  };

  return (
    <NotificationContext.Provider value={providerValue}>
      {children}
    </NotificationContext.Provider>
  );
};
