import React from "react";
import Sidebar from "../components/Sidebar";
import NominationCard from "../components/Nomination/NominationCard";
import "./table-style.css";
import { NominationContextProvider } from "../components/Nomination/NominationContext";

const NominationPage = () => {
  return (
    <div>
       <NominationContextProvider>
        <Sidebar />
        <NominationCard />
      </NominationContextProvider>
    </div>
  );
};

export default NominationPage;
