import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const PopupB = ({
  show,
  handleClose,
  title,
  firstDropDown,
  dropDownA,
  dropDownB,
  dropDownATitle,
  dropDownBTitle,
  inputValueTitle,
  firstDropDownTitle,
  zoneList,
  onPositionCountSelect,
  onTargetVotersZoneSelect,
  onTargetNomineeZoneSelect,
  targetVotersZone,
  targetNomineeZone,
  numberOfPositions,
  onPositionNameChange,
  positionName,
  saveData,
  positionNameValidation,
  numberOfPositionsValidation,
  setPositionNameValidation,
  setNumberOfPositionsValidation,
  gender,
  setGender,
  firstDropDownTitleGender,
  editItem,
  setTargetVotersZoneValidation,
  setTargetNomineeZoneValidation,
  targetVotersZoneValidation,
  targetNomineeZoneValidation
}) => {
  let genderArray =["All","Female"];
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h5>{title}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="form-group">
            {inputValueTitle && (
              <label>
                {inputValueTitle} <span className="reqStar">*</span>
              </label>
            )}
            <input
              type="text"
              placeholder="Position name"
              className={"form-control inputDec "+(positionNameValidation?"border-validation":"")}
              onFocus={()=>setPositionNameValidation(false)}
              onChange={(e)=>onPositionNameChange(e.target.value)} 
              value={positionName}
            />
          </div>
          <div className="row divspace">
          <div className="col-md-6">
            {firstDropDownTitle && (
              <label className="lbl-text">
                {firstDropDownTitle}
                <span className="reqStar">*</span>
              </label>
            )}

            <input
              type="number"
              placeholder="Number of Positions"
              className={"form-control inputDec "+(numberOfPositionsValidation?"border-validation":"")}
              onFocus={()=>setNumberOfPositionsValidation(false)}
              onChange={(e)=>onPositionCountSelect(e.target.value)} 
              value={numberOfPositions}
            />
            </div>
            <div className="col-md-6">
            {firstDropDownTitleGender && (
              <label className="lbl-text">
                {firstDropDownTitleGender}
              </label>
            )}

            <select onChange={(e) => setGender(e.target.value)} value={gender} className={"form-control selectdec"}>
            <option value="">Select</option>
              {genderArray.map((item, index) => {
                    return <option key={index} value={item}>{item}</option>;
                  })}
            </select>
            </div>
          </div>

          <div className="row">
            {dropDownBTitle && (
              <div className="col-md-6">
                <label className="lbl-text">
                  {dropDownBTitle} <span className="reqStar">*</span>
                </label>
                <select onChange={(e) => onTargetVotersZoneSelect(e.target.value)} onFocus={()=>setTargetVotersZoneValidation(false)} value={targetVotersZone} className={"form-control inputDec "+(targetVotersZoneValidation?"border-validation":"")}>
                 <option value="">Select</option>
                  {zoneList.map((item, index) => {
                    return <option key={index} value={item._id}>{item.name}</option>;
                  })}
                </select>
              </div>
            )}
            {dropDownATitle && (
              <div className="col-md-6 modalInput">
                <label className="lbl-text">
                  {dropDownATitle} <span className="reqStar">*</span>
                </label>
                <select onChange={(e) => onTargetNomineeZoneSelect(e.target.value)} onFocus={()=>setTargetNomineeZoneValidation(false)} value={targetNomineeZone} className={"form-control inputDec "+(targetNomineeZoneValidation?"border-validation":"")}>
                  <option value="">Select</option>
                  {zoneList.map((item, index) => {
                    return <option key={index} value={item._id}>{item.name}</option>;
                  })}
                </select>
              </div>
            )}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <p className="text-primary btn mt-2" onClick={handleClose}>
          Cancel
        </p>
        <Button variant="primary" onClick={()=>saveData()}>
          {editItem?"Update":"Add"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopupB;
