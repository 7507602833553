import React,{ useContext } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import {NominationDetailsContext} from './NominationDetailsContext';

const NominationDetailsTable = () => {
  const nominationDetailsModel = useContext(NominationDetailsContext);
  const {
    nominationDetailsLogs,
    columnData
  } = nominationDetailsModel;

    const { SearchBar } = Search;
    const pagination = paginationFactory({
      page: 1,
      sizePerPage: 10,
      lastPageText: "Last",
      firstPageText: "First",
      nextPageText: "Next",
      prePageText: "Previous",
      showTotal: true,
      alwaysShowAllBtns: true,
      onPageChange: function (page, sizePerPage) {
      },
      onSizePerPageChange: function (page, sizePerPage) {
      },
    });

  return (
    <div>
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-start justify-content-between">
                    <p className="card-title flex-grow">Logs</p>
                  </div>

                  <ToolkitProvider
                    keyField="id"
                    data={nominationDetailsLogs}
                    columns={columnData}
                    search
                  >
                    {(props) => (
                      <div>
                        <span className="srch-box ">
                          <label className="mr-2">Search:</label>
                          <SearchBar
                            {...props.searchProps}
                            className="inpt-box"
                          />
                        </span>

                        <BootstrapTable
                          {...props.baseProps}
                          pagination={pagination}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          </div>
  </div>);
};

export default NominationDetailsTable;
