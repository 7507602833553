import React from "react";
import NotificationCard from "../components/Notification/NotificationCard";
import Sidebar from "../components/Sidebar";
import { NotificationContextProvider } from "../components/Notification/NotificationContext";

const NotificationPage = () => {
  return (
    <div>
      <NotificationContextProvider>
        <Sidebar />
        <NotificationCard />
      </NotificationContextProvider>
    </div>
  );
};

export default NotificationPage;
