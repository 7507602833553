import React, {createContext,useState,useEffect,useContext} from "react";
import API from "../../api/api";
import { useHistory,useLocation } from "react-router-dom";
import routes from '../../constants/routes.json';
import {store} from "../../store/store";
  
export const UserProfileContext = createContext();
  export const UserProfileContextProvider = ({ children }) => {
    const URL2 = process.env.REACT_APP_IMAGE_SERVER;
    const globalState = useContext(store);

    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [zone, setZone] = useState("");
    const [userDetails, setUserDetails] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [zoneList, setZoneList] = useState([]);   
    const [emailValidation,setEmailValidation]=useState(false);
    const [phoneNumberValidation, setPhoneNumberValidation] = useState(false); 
    const [showPopup, setShowPopup] = useState(false);  
    const [imageurl, setImageurl] = useState("");

    const getMemberDetailsFromApi = async (tocken) => {
      API.getMemberDetails(searchText,tocken)
        .then(async (response) => {
          const data = response.data;
          setUserDetails(data);
          setZone(data.zone?data.zone._id:"");
          setPhoneNumber(data.mobile);
          setEmail(data.email);
          setSearchText("");
          setImageurl(data.profile_image && data.profile_image!==""?URL2+data.profile_image:"")
        })
        .catch((e) => {
          console.log("error of Nomination Details list", e);
        });
    };
    const updateMemberDataApi = async (tocken) => {
      API.putMemberDetails(userDetails.pf_number,tocken,phoneNumber,zone,email)
        .then(async (response) => {
          setUserDetails([]);
          setZone("");
          setPhoneNumber("");
          setEmail("");
          setImageurl("");
          setShowPopup(true);
        })
        .catch((e) => {
          console.log("error of nomination status change", e);
        });
    };
    const getElectionTokenApi = async (type) => {
      API.getElectionToken(globalState.state.user.phone , globalState.state.token)
        .then(async (response) => {
          if(type==="get"){
            getMemberDetailsFromApi(response.data.token);
          }
          else if(type==="save"){
            updateMemberDataApi(response.data.token);
          }
          else{
            fetchZoneList(response.data.token);
          }
        })
        .catch((e) => {
          console.log("error of settings", e);
        });
    };

    const fetchZoneList =(token)=>{
      API.getElectionZone(token)
      .then(async (response) => {
        setZoneList(response.data.data.zoneList);
      })
      .catch((e) => {
        console.log("error of commision list", e);
      });
    }

    const getElectionZoneList=()=>{
      getElectionTokenApi("zone")
    }

    const getMember=()=>{
      getElectionTokenApi("get")
    }
    
    const onSave=()=>{
      if(phoneNumber==="")
      {
        setPhoneNumberValidation(true);
      }
      if(userDetails.pf_number !=="" && phoneNumber !=="" && validateEmail(email)){
      getElectionTokenApi("save")
      }
    }
    const clearUserData=()=>{
      setUserDetails([]);
      setZone("");
      setPhoneNumber("");
      setEmail("");
    }
    const onZoneSelect=(value)=>{
      setZone(value);
    }
    const validateEmail = (email) => {
      if(email!==""){
      const pattern = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
      setEmailValidation(!pattern.test(email));
      return pattern.test(email);
      }
      else{
        setEmailValidation(false)
        return true
      }
    }
    useEffect(async () => {
      getElectionZoneList();
    }, []);
  
    const providerValue = {
      userDetails,
      setEmail,
      email,
      setPhoneNumber,
      phoneNumber,
      onZoneSelect,
      zone,
      clearUserData,
      onSave,
      getMember,
      setSearchText,
      searchText,
      zoneList,
      setPhoneNumberValidation,
      phoneNumberValidation,
      emailValidation,
      setEmailValidation,
      setShowPopup,
      showPopup,
      imageurl
    };
  
    return (
      <UserProfileContext.Provider value={providerValue}>
        {children}
      </UserProfileContext.Provider>
    );
  };
  