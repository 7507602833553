import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import API from "../../api/api";
import PositionColumns from "../../constants/PositionColumns";
import PencilIcon from "mdi-react/PencilIcon";
import DeleteIcon from "mdi-react/DeleteIcon";
import {store} from "../../store/store";

export const PositionContext = createContext();

export const PositionContextProvider = ({ children }) => {
  const globalState = useContext(store);
  const [positions, setPositions] = useState([]);
  const [editButtonClick, setEditButtonClick] = useState(false);
  const [addNewPosition, setNewPosition] = useState(false);
  const [columnData, setColumnData] = useState(PositionColumns);
  const [show, setShow] = useState(false);
  const [positionName, setPositionName] = useState("");
  const [numberOfPositions, setNumberOfPositions] = useState(0);
  const [targetNomineeZone, setTargetNomineeZone] = useState("");
  const [targetVotersZone, setTargetVotersZone] = useState("");
  const [saveTrigger, setSaveTrigger] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [zoneList, setZoneList] = useState([]);
  const [announceItem, setAnnounceItem] = useState("");
  const [gender, setGender] = useState("");
  const [positionNameValidation, setPositionNameValidation] = useState(false);
  const [numberOfPositionsValidation, setNumberOfPositionsValidation] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [deletepositionId, setDeletepositionId] = useState("");
  const [targetVotersZoneValidation,setTargetVotersZoneValidation] = useState(false);
  const [targetNomineeZoneValidation,setTargetNomineeZoneValidation] = useState(false);

  const handleClose = () => {
    setShow(false);
    setNumberOfPositions(0);
    setTargetNomineeZone("");
    setTargetVotersZone("");
    setPositionName("");
    setPositionNameValidation(false);
    setNumberOfPositionsValidation(false);
    setTargetVotersZoneValidation(false);
    setTargetNomineeZoneValidation(false);
    setEditItem(false);
    setGender("");
    setNewPosition(false);
  }
  const handleShow = () => {
    setShow(true);
    setNumberOfPositions(0);
    setTargetNomineeZone("");
    setTargetVotersZone("");
    setPositionName("");
  };

  const editPosition = (item) => {
    setSelectedId(item._id);
    setEditItem(true);
    setPositionName(item.name);
    setNumberOfPositions(item.totalPositions);
    setTargetNomineeZone(item.targetNomineeZone._id);
    setTargetVotersZone(item.targetVotersZone._id);
    setGender(item.gender);
    setShow(true);
  };

  const announceStageItem = (item) => {};

  const changeObjStructure = async (data) => {
    const actionIncluded = await data.map((item) => ({
      ...item,
      action: (
        <div className="d-flex align-items-center ml-3">
          <div className="icon-square-sm-success hover-cursor align-items-center d-flex justify-content-center">
            <PencilIcon
              size={16}
              className="text-success"
              onClick={() => editPosition(item)}
            />
          </div>
          {false &&
          <div className="icon-square-sm-danger hover-cursor align-items-center d-flex justify-content-center ml-4">
            <DeleteIcon onClick={() => deletePositionOnClick(item._id)} size={16} className="text-danger" />
          </div>
          }
        </div>
      ),
    }));
    return actionIncluded;
  };

  const getPositionsList = async () => {
    API.getAssociationPosition(globalState.state.token)
      .then(async (response) => {
        const data = await changeObjStructure(response.data.association_list);
        setPositions(data);
      })
      .catch((e) => {
        console.log("error of position list", e);
      });
  };

  useEffect(() => {}, [addNewPosition]);

  const fetchZoneList =()=>{
    API.getZoneList(globalState.state.token)
    .then(async (response) => {
      setZoneList(response.data.zone_list);
    })
    .catch((e) => {
      console.log("error of commision list", e);
    });
  }
  
  const onPositionCountSelect =(value)=>{
    setNumberOfPositions(value);
    if(value!==0){
    setNumberOfPositionsValidation(false)
    }
  }
  const onTargetVotersZoneSelect =(value)=>{
    if(value!==""){
    setTargetVotersZoneValidation(false);
    }
    setTargetVotersZone(value);
  }
  const onTargetNomineeZoneSelect =(value)=>{
    if(value!==""){
    setTargetNomineeZoneValidation(false);
    }
    setTargetNomineeZone(value);
  }
  const onPositionNameChange =(value)=>{
    setPositionName(value);
  }
  const saveData =()=>{
    if(positionName===""){
      setPositionNameValidation(true);
    }
    if(numberOfPositions<=0){
      setNumberOfPositionsValidation(true);
    }
    if(targetNomineeZone===""){
      setTargetNomineeZoneValidation(true)
    }
    if(targetVotersZone===""){
      setTargetVotersZoneValidation(true);
    }
    if(positionName!=="" && numberOfPositions>0 && targetVotersZone!=="" && targetNomineeZone!==""){
      if(editItem){
        updatePositionApi();
      }
      else{
      savePositionApi();
      }
    }
  }

  const savePositionApi = async () => {
    API.saveAssociationPosition(positionName,numberOfPositions,targetNomineeZone,targetVotersZone,gender,globalState.state.token)
      .then(async (response) => {
        getPositionsList();
        handleClose();
      })
      .catch((e) => {
        console.log("error of save position", e);
      });
  };

  const updatePositionApi = async () => {
    API.updateAssociationPosition(selectedId,positionName,numberOfPositions,targetNomineeZone,targetVotersZone,gender,globalState.state.token)
      .then(async (response) => {
        getPositionsList();
        handleClose();
      })
      .catch((e) => {
        console.log("error of update position", e);
      });
  };
  const deletePositionOnClick = async (id) => {
    setDeletepositionId(id);
    setMessage("Delete selected position, Are you sure?");
    setShowPopup(true);
  };

  const onConfirmClick=async()=>{
    API.deleteAssociationPosition(deletepositionId,globalState.state.token)
    .then(async (response) => {
      getPositionsList();
      setShowPopup(false);
      setDeletepositionId("");
      setMessage("");
    })
    .catch((e) => {
      console.log("error of delete position", e);
    });
  }

  useEffect(async () => {
    getPositionsList();
    fetchZoneList();
    localStorage.setItem("statusFilter", "");
    localStorage.setItem("positionFilter", "");
  }, []);

  const providerValue = {
    positions,
    columnData,
    show,
    editButtonClick,
    addNewPosition,
    positionName,
    numberOfPositions,
    targetNomineeZone,
    targetVotersZone,

    setPositions,
    setColumnData,
    setShow,
    handleClose,
    handleShow,
    setPositionName,
    setEditButtonClick,
    setNewPosition,
    setNumberOfPositions,
    setSaveTrigger,
    saveData,
    setTargetNomineeZone,
    setTargetVotersZone,
    zoneList,
    onPositionCountSelect,
    onTargetVotersZoneSelect,
    onTargetNomineeZoneSelect,
    onPositionNameChange,
    editItem,
    positionNameValidation,
    numberOfPositionsValidation,
    setPositionNameValidation,
    setNumberOfPositionsValidation,
    gender,
    setGender,
    showPopup,
    setShowPopup,
    isSuccess,
    onConfirmClick,
    message,
    setTargetVotersZoneValidation,
    setTargetNomineeZoneValidation,
    targetVotersZoneValidation,
    targetNomineeZoneValidation
    
  };

  return (
    <PositionContext.Provider value={providerValue}>
      {children}
    </PositionContext.Provider>
  );
};
