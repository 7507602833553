import axios from "axios";
import Requests from "../constants/requests.json";
const URL = process.env.REACT_APP_API_URL;
const URL2 = process.env.REACT_APP_USER_API_URL;
// const token = localStorage.getItem('token');
// const parseQueryParams = (params = {}) => {
//   if (!Object.entries(params).length) return "";
//   return Object.entries(params).reduce(
//     (acc, [key, value]) => acc + `${key}=${value}&`,
//     "?"
//   );
// };

const Api = {
  userLogin({ phoneNumber }) {
    return axios.post(URL + Requests.LOGIN, {
      mobile: phoneNumber,
    });
  },
  verifyOtp({ otp, phoneNumber }) {
    return axios.post(URL + Requests.OTP, {
      mobile: phoneNumber,
      otp: otp,
    });
  },
  addStage(stageName, stageDescription, startDate, endDate, userToken) {
    return axios.post(URL + Requests.SCHEDULE_STAGE, {
      name: stageName,
      description: stageDescription,
      startAt: startDate,
      endAt: endDate,
    }, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });
  },
  getStages(userToken) {
    return axios.get(URL + Requests.SCHEDULE_STAGE, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });
  },
  // getSingleState(id) {
  //   return axios.get(URL + `${Requests.SCHEDULE_STAGE}/${id}`, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     }
  //   });
  // },
  editState(selectedId, stageName, stageDescription, startDate, endDate, userToken) {
    return axios.patch(URL + `${Requests.SCHEDULE_STAGE}/${selectedId}`, {
      name: stageName,
      description: stageDescription,
      startAt: startDate,
      endAt: endDate,
    }, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });
  },
  // getCommisionList() {
  //   return axios.get(URL + Requests.COMMISSION, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     }
  //   });
  // },
  getNominations(userToken) {
    return axios.get(URL + Requests.NOMINATIONS + "?page=1&size=10000", {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });
  },
  getMultipleNominations(userToken) {
    return axios.get(URL + Requests.MULTIPLE_NOMINATIONS + "?page=1&size=10000", {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });
  },
  rejectMultipleNominations(userToken, memberId, remarks) {
    return axios.post(URL + Requests.REJECT_MULTIPLE_NOMINATIONS, {
      memberId: memberId,
      remarks: remarks
    }, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });
  },
  getNominationDetails(nominationId, userToken) {
    return axios.get(URL + `${Requests.NOMINATIONS}/${nominationId}`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });
  },
  getNominationDetailLogs(nominationId, userToken) {
    return axios.get(URL + `${Requests.NOMINATIONDTLLOGS}/${nominationId}` + "?page=1&size=10000", {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });
  },
  setNominationStatus(nominationId, status, note, userToken) {
    return axios.patch(URL + `${Requests.NOMINATIONS}/${nominationId}`, {
      status: status,
      remarks: note,
    }, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });
  },
  getAssociationPosition(userToken) {
    return axios.get(URL + Requests.ASSOCIATION_POSITION + "?page=1&size=10000", {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });
  },
  getZoneList(userToken) {
    return axios.get(URL + Requests.ZONES + "?page=1&size=10000", {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });
  },
  getNotifications(userToken) {
    return axios.get(URL + Requests.NOTIFICATIONS + "?page=1&size=10000", {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });
  },
  updateNotifications(notificationTitle, description, targetZone, scheduleDate, status, selectedId, userToken) {
    return axios.patch(URL + Requests.NOTIFICATIONS + "/" + selectedId,
      {
        title: notificationTitle,
        message: description,
        targetZone: targetZone,
        scheduledAt: scheduleDate,
        status: status
      }, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });
  },
  deleteNotifications(selectedId, userToken) {
    return axios.delete(URL + Requests.NOTIFICATIONS + "/" + selectedId, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });
  },
  getSettings(userToken) {
    return axios.get(URL + Requests.SETTINGS, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });
  },
  updateSettings(id, isEnabled, userToken) {
    return axios.patch(URL + `${Requests.SETTINGS}/${id}`, {
      isEnabled,
    }, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });
  },
  saveAssociationPosition(name, totalPositions, targetNomineeZone, targetVotersZone, gender, userToken) {
    return axios.post(URL + `${Requests.ASSOCIATION_POSITION}`, {
      name: name,
      totalPositions: totalPositions,
      targetNomineeZone: targetNomineeZone,
      targetVotersZone: targetVotersZone,
      gender: gender
    }, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });
  },
  updateAssociationPosition(associationId, name, totalPositions, targetNomineeZone, targetVotersZone, gender, userToken) {
    return axios.patch(URL + `${Requests.ASSOCIATION_POSITION}/${associationId}`, {
      name: name,
      totalPositions: totalPositions,
      targetNomineeZone: targetNomineeZone,
      targetVotersZone: targetVotersZone,
      gender: gender
    }, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });
  },
  deleteAssociationPosition(associationId, userToken) {
    return axios.delete(URL + `${Requests.ASSOCIATION_POSITION}/${associationId}`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });
  },
  getResult(userToken) {
    return axios.get(URL + `${Requests.RESULT}`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });
  },
  setResultVerified(positionId, nominationId, userToken) {
    return axios.post(URL + `${Requests.RESULT_VERIFY}`, {
      positionId: positionId,
      nominationId: nominationId
    }, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });
  },
  postPublishResult(positionId, userToken) {
    return axios.post(URL + `${Requests.RESULT_PUBLISH}`, {
      positionId: positionId,
    }, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });
  },
  getElectionToken(phoneNumber) {
    try{
      return axios.post(URL2 + Requests.ELECTIONTOKEN,
        {
          mobile: `${phoneNumber}`,
        },
        {
          headers: {
            "is-election-commission": "TRUE",
          },
        });
    } catch{}
 
  },
  getMemberDetails(pfNumber, token) {
    return axios.get(URL2 + `${Requests.MEMBERDETAILS}/` + pfNumber, {
      headers: {
        "election-token": token
      },
    });
  },
  putMemberDetails(pfNumber, token, mobile, zone, email) {
    return axios.put(URL2 + `${Requests.MEMBERDETAILS}/` + pfNumber, {
      mobile: mobile,
      zone: zone,
      email: email
    }, {
      headers: {
        "election-token": token
      }
    });
  },
  getElectionZone(token) {
    return axios.get(URL2 + `${Requests.ELECTIONZONE}`, {
      headers: {
        "election-token": token
      },
    });
  },
  getResultStartButtonStatus(userToken) {
    return axios.get(URL + `${Requests.CHECK_START_RESULT_STATUS}`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });
  },
  
  startResultCalculation(userToken) {
    return axios.post(URL + `${Requests.START_RESULT_COUNTING}`, {
      "confirm": true
    }, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });
  },

  getStatsData(userToken){
    return axios.get(URL + `${Requests.GET_STATS_DATA}`,  {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    });

  },

  sedMessage(token, message){
    return axios.post(URL2 + `${Requests.SMS }`,{
      "message_content": message
    }, {
      headers: {
        "election-token": token
      }
    });
  }
};
export default Api;

