import React, { createContext, useState, useEffect, useContext } from "react";
import API from "../../api/api";
import { useHistory, useLocation } from "react-router-dom";
import { store } from "../../store/store";

export const ResultContext = createContext();
export const ResultContextProvider = ({ children }) => {
  const history = useHistory();
  const globalState = useContext(store);
  const [electionResults, setElectionResults] = useState([]);
  const [verifiedResults, setVerifiedResults] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [startResultBtnStatus, setStartResultBtnStatus] = useState(false);
  const [spinner, setSpinner] = useState(true);

  const getResultsFromApi = async () => {
    let VerifyList = [];
    let nominations = [];
    let verifyCount = 0;
    let verified = false;
    let haveWinner = false;
    API.getResult(globalState.state.token)
      .then(async (response) => {
        const resultData = response.data.election_results;
        setElectionResults(resultData);
        resultData.map((item, index) => {
          if (item.results && item.results.length > 0) {
            item.results.map((nomination, index) => {
              if (
                nomination &&
                nomination.verifiedBy &&
                nomination.verifiedBy.length > 0
              ) {
                verifyCount =
                  nomination.verifiedBy.length > verifyCount
                    ? nomination.verifiedBy.length
                    : verifyCount;
                if (
                  nomination.verifiedBy.filter(
                    (x) => x.userId === globalState.state.user._id
                  ).length > 0
                ) {
                  nominations.push(nomination.nominationId);
                  verified = true;
                }
              }
              haveWinner = nomination?.isWinner === true ? true : haveWinner;
            });
            let data = {
              positionId: item._id,
              nominations: nominations,
              verifyCount: verifyCount,
              verified: verified,
              haveWinner: haveWinner,
            };
            VerifyList.push(data);
            verifyCount = 0;
            nominations = [];
            verified = false;
            haveWinner = false;
          }
        });
        setVerifiedResults(VerifyList);
      })
      .catch((e) => {
        console.log("error of Result", e);
      });
  };
  const toggleVerify = (
    positionId,
    nominationID,
    totalPositions,
    haveWinner,
    VerifiedCount,
    positionName,
    verified
  ) => {
    if (haveWinner === false && verified === false) {
      let verifyData = [];
      let nominationIndex = -1;
      const verifiedPosition = verifiedResults.filter(
        (x) => x.positionId === positionId
      );
      if (verifiedPosition.length > 0) {
        if (verifiedPosition[0].nominations !== undefined) {
          nominationIndex =
            verifiedPosition[0].nominations.indexOf(nominationID);
        }
        if (nominationIndex === -1) {
          if (verifiedPosition[0].nominations.length < totalPositions) {
            verifiedPosition[0].nominations.push(nominationID);
          } else {
            setMessage(
              "Maximum number of winners for the position '" +
              positionName +
              "' should be " +
              totalPositions
            );
            setShowPopup(true);
          }
        } else {
          verifiedPosition[0].nominations.splice(nominationIndex, 1);
        }
        let index = verifiedResults.findIndex(
          (x) => x.positionId === positionId
        );
        verifyData = verifiedResults.slice();
        verifyData.splice(index, 1);
        verifyData.push(verifiedPosition[0]);
      } else {
        verifyData = verifiedResults;
        let data = {
          positionId: positionId,
          nominations: [nominationID],
          verifyCount: 1,
          verified: false,
          haveWinner: false,
        };
        verifyData.push(data);
      }
      setVerifiedResults(verifyData);
    }
  };
  const onResultVerified = (positionId) => {
    let positionData = verifiedResults.filter(x => x.positionId === positionId)[0];
    if (positionData && positionData.nominations && positionData.nominations.length > 0) {
      let totalVerifycount = positionData.nominations.length;
      positionData.nominations.map((nominationId, index) => {
        API.setResultVerified(positionId, nominationId, globalState.state.token)
          .then(async (response) => {
            totalVerifycount--;
            if (totalVerifycount === 0) {
              getResultsFromApi();
            }
          })
          .catch((e) => {
            console.log("error of verify Result", e);
          });
      });
    }
  };
  const onPublishClick = (positionId) => {
    API.postPublishResult(positionId, globalState.state.token)
      .then(async () => {
        getResultsFromApi();
      })
      .catch((e) => {
        console.log("error of verify Result", e);
      });
  }
  useEffect(async () => {
    setTimeout(() => { setSpinner(false) }, 1000);
    API.getResultStartButtonStatus(globalState.state.token).then((response) => {
      setStartResultBtnStatus(response);
    }).catch((e) => { });
    getResultsFromApi();
    localStorage.setItem("statusFilter", "");
    localStorage.setItem("positionFilter", "");
  }, []);

  const startResultCalculation = () => {
    API.startResultCalculation(globalState.state.token).then((res) => { }).catch((e) => { });
  }

  const providerValue = {
    electionResults,
    setElectionResults,
    onResultVerified,
    verifiedResults,
    toggleVerify,
    showPopup,
    setShowPopup,
    message,
    onPublishClick,
    startResultBtnStatus,
    startResultCalculation,
    spinner
  };

  return (
    <ResultContext.Provider value={providerValue}>
      {children}
    </ResultContext.Provider>
  );
};
