import React, { createContext, useState, useContext,useEffect } from "react";
import { useHistory } from "react-router-dom";
import { store } from "../../store/store";
import api from "../../api/api";
import { USER_LOGIN,TOKEN,IS_CHIEF_ELECTORAL_OFFICER,USER,WITHDRAW_NOMINATIONS,DECLARE_RESULT,LOCK_RESULTS,PUBLISH_CANDIDATE_LIST,VOTING,VOTING_ENABLECOUNT,ONBOARD_NEW_COMMITTEE_ENABLECOUNT,ONBOARD_NEW_COMMITTEE} from "../../store/types";
import routes from '../../constants/routes.json';
import constants from '../../constants/settings.json';

export const LoginContext = createContext();

export const LoginContextProvider = ({ children }) => {
  const history = useHistory();
  const globalState = useContext(store);
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [otp, setOtp] = useState(0);
  const [spinnerSignin, setSpinnerSignin] = useState(false);
  const [signinErrorMsg, setSigninErrorMsg] = useState("");
  const [userAuthStatus, setUserAuthStatus] = useState(
    globalState.state.authLogin
  );
  const { dispatch } = globalState;

  const otpRequest = async () => {
    setSigninErrorMsg("");
    if (phoneNumber.length > 9) {
      const result = await api
        .userLogin({ phoneNumber })
        .then(() => {
          return true;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.message)
          {
            return error.response.data.message;
          }
          else{
          return false;
          }
        });

      return result;
    }
  };

  const verifyOtp = async () => {
    setSpinnerSignin(true);
    setSigninErrorMsg("");
    // dispatch({ type: USER_LOGIN, payload: true });
    // dispatch({ type: TOKEN, payload: localStorage.getItem('token')});
    // history.push(routes.SCHEDULE_PAGE);
    // dispatch({ type: IS_CHIEF_ELECTORAL_OFFICER, payload: true });
    if (phoneNumber.length > 9) {
      const result = await api
        .verifyOtp({ phoneNumber, otp })
        .then((response) => {
          setSpinnerSignin(false);
          setSigninErrorMsg("");
          // localStorage.setItem("token", response.data.token);
          localStorage.setItem("pageSize", 10);
          dispatch({ type: USER_LOGIN, payload: true });
          dispatch({ type: TOKEN, payload: response.data.token });
          dispatch({ type: IS_CHIEF_ELECTORAL_OFFICER, payload: (response.data?.user_details&&response.data.user_details?.position===constants.CHIEF_ELECTORAL_OFFICER?true:false) });
          dispatch({ type: USER, payload: (response.data?.user_details) });
          //dispatch({ type: IS_CHIEF_ELECTORAL_OFFICER, payload: true });
          getSettingsApi(response.data.token);
          history.push(routes.POSITION_PAGE);
          
        })
        .catch((error,response) => {
          console.log("error",error.response.data.message,response)
          setSpinnerSignin(false);
          setSigninErrorMsg(error.response.data.message);
          return false;
        });
    }
  };

  const getSettingsApi = async (token) => {
    api.getSettings(token)
      .then(async (response) => {
        const data = response.data.settings_list;
        data.map((settings) => {
          switch (settings.title.trim()) {
            case constants.WITHDRAW_NOMINATIONS:
              dispatch({
                type: WITHDRAW_NOMINATIONS,
                payload: settings.isEnabled,
              });
              break;
            case constants.DECLARE_RESULT:
              dispatch({
                type: DECLARE_RESULT,
                payload: settings.isEnabled,
              });
              break;
            case constants.LOCK_RESULTS:
              dispatch({
                type: LOCK_RESULTS,
                payload: settings.isEnabled,
              });
              break;
            case constants.PUBLISH_CANDIDATE_LIST:
              dispatch({
                type: PUBLISH_CANDIDATE_LIST,
                payload: settings.isEnabled,
              });
              break;
            case constants.VOTING:
              dispatch({
                type: VOTING,
                payload: settings.isEnabled,
              });
              dispatch({
                type: VOTING_ENABLECOUNT,
                payload: settings.enabledCount,
              });
              break;
            case constants.ONBOARD_NEW_COMMITTEE:
              dispatch({
                type: ONBOARD_NEW_COMMITTEE,
                payload: settings.isEnabled,
              });
              dispatch({
                type: ONBOARD_NEW_COMMITTEE_ENABLECOUNT,
                payload: settings.enabledCount,
              });
              break;  
            default:
              break;
          }
        });
      })
      .catch((e) => {
        console.log("error of settings", e);
      });
  };

  const providerValue = {
    phoneNumber,
    otp,
    userAuthStatus,

    setPhoneNumber,
    setOtp,
    setUserAuthStatus,
    otpRequest,
    verifyOtp,
    spinnerSignin,
    signinErrorMsg,
    setSigninErrorMsg
  };

  useEffect(async () => {
    setSpinnerSignin(false);
    setSigninErrorMsg("");
    localStorage.setItem("statusFilter", "");
    localStorage.setItem("positionFilter", "");
  }, []);

  return (
    <LoginContext.Provider value={providerValue}>
      {children}
    </LoginContext.Provider>
  );
};
