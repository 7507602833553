import React from 'react';
import CarouselCard from '../components/CarouselCard';
import Sidebar from '../components/Sidebar';

const PublishPage = () => {
    return <div>
        <Sidebar/>
        <CarouselCard/>
    </div>;
}

export default PublishPage;