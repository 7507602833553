import React, { useContext } from 'react';
import Candidate from "../../assets/images/img-avatar.png"
import {UserProfileContext } from './UserProfileContext';
import PopupD from "../PopupD";

const UserProfileCard = () => {
      const userProfileModel = useContext(UserProfileContext);
      const {
        userDetails,
        setEmail,
        email,
        setPhoneNumber,
        phoneNumber,
        onZoneSelect,
        zone,
        getMember,
        setSearchText,
        searchText,
        zoneList,
        setPhoneNumberValidation,
        phoneNumberValidation,
        onSave,
        clearUserData,
        setEmailValidation,
        emailValidation,
        showPopup,
        setShowPopup,
        imageurl
      } = userProfileModel;
    return (
    <div>
        <div className="main-panel">
        <div className="content-wrapper">
          <div className="row">
            <div className="col-md-12 grid-margin">
              <div className="d-flex justify-content-between flex-wrap">
                <div className="d-flex align-items-center dashboard-header flex-wrap mb-3 mb-sm-0">
                  <h4 className="mr-4 mb-0 font-weight-bold">Member Details</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                  <div className="col-md-6 detailBox" style={{display:"inline-flex"}}>
                  <input onChange={(e) => setSearchText(e.target.value)} className={"form-control selectcontrol"} value={searchText} placeholder="PF number"/>
                  <button className="btn btn-primary  ml-3 btnRight" type="button" onClick={() =>getMember()}>Get Member</button>
                  </div>
                  {userDetails && userDetails.pf_number &&
                  <form>
                    <div  className="row mt-5">
                  <div className="col-md-6 detailBox">
                      <div className="row"> 
                      <div className="col-md-4">
                        <div className="img-box " style={{margin:"auto"}}>
                            <img src={imageurl} onError={(e) => { e.target.onerror = null; e.target.src = Candidate }} alt="" className="imgdiv"/> 
                        </div>
                        <label className="d-flex justify-content-center mt-2">Member Name</label>
                          <span className="d-flex justify-content-center aligncenter detailBoxBold">{userDetails?userDetails.name:""}</span> 
                      </div>
                      <div className="col-md-8 detailBox">
                        <label className="required">PF Number</label>
                        <div className="detailBoxBold" style={{minHeight:38}}>{userDetails && userDetails.pf_number?userDetails.pf_number:"-"}</div> 

                        <label className="required mt-4">Phone<span className="reqStar">*</span></label>
                        <input 
                          onChange={(e) => setPhoneNumber(e.target.value)} 
                          onFocus={()=>setPhoneNumberValidation(false)} 
                          className={"form-control selectcontrol " + (phoneNumberValidation?"border-validation":"")}
                          value={phoneNumber} />
                      </div>
                    </div>
                  </div>
      
                  <div className="col-md-6 detailBox">
                  <div className="col-md-8 detailBox">
                        <label className="lbl-text">{"Zone"}</label>
                        <div className="input-group">
                        <select onChange={(e) => onZoneSelect(e.target.value)} value={zone} className={"form-control inputDec "}>
                            <option value="0">Select</option>
                            {zoneList.map((item, index) => {
                              return <option key={index} value={item._id}>{item.name}</option>;
                            })}
                          </select>
                        </div>
                      <label className="required mt-4">Email</label>
                      <input 
                          onChange={(e) => setEmail(e.target.value)} 
                          onFocus={()=>setEmailValidation(false)} 
                          className={"form-control selectcontrol " + (emailValidation?"border-validation":"")}
                          value={email} />

                  <div className="col-md-12 " style={{marginTop:20}}> 
                    <button className="btn btn-primary  ml-3 btnRight" type="button" onClick={() =>onSave()}>Save</button>
                    <button className="btn btn-txt btnRight" type="button" onClick={() =>clearUserData()}>Clear</button>
                   </div>
                  </div>
                  </div>

                </div>
                
                </form>
                }  
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div> 
      <PopupD
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        isSuccess={true}
        message={"Member details updated"}
        isWarning={false}
      />
    </div>);
}

export default UserProfileCard;