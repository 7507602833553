import React from "react";
import BootstrapTableContainer from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

const ContainerForTable = ({
  title,
  subTitle,
  handleShow,
  products,
  columns,
  SearchBar,
  pagination,
  buttonText,
  rowClasses
}) => {
  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="row">
          <div className="col-md-12 grid-margin">
            <div className="d-flex justify-content-between flex-wrap">
              <div className="d-flex align-items-center dashboard-header flex-wrap mb-3 mb-sm-0">
                <h4 className="mr-4 mb-0 font-weight-bold">{title}</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-start justify-content-between">
                  <p className="card-title flex-grow">{subTitle}</p>
                  {handleShow && buttonText && (
                    <button
                      className="btn btn-primary pull-right"
                      type="submit"
                      onClick={handleShow}
                    >
                      {buttonText}
                    </button>
                  )}
                </div>
                <ToolkitProvider
                  keyField="id"
                  data={products}
                  columns={columns}
                  search
                >
                  {(props) => (
                    <div>
                      {SearchBar && (
                        <span className="srch-box mt-4">
                          <label className="mr-2">Search:</label>
                          <SearchBar
                            {...props.searchProps}
                            className="inpt-box"
                          />
                        </span>
                      )}

                      <BootstrapTable
                        {...props.baseProps}
                        pagination={pagination}
                        rowClasses={rowClasses}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContainerForTable;
