import React from "react";
import Sidebar from "../components/Sidebar";
import TableCard from "../components/ScheduleTable/TableCard";
import "./table-style.css";
import { ScheduleContextProvider } from "../components/ScheduleTable/TableCardContext";
const SchedulePage = () => {
  return (
    <div className="schedule-table-cust">
      <ScheduleContextProvider>
        <Sidebar />
        <TableCard />
      </ScheduleContextProvider>
    </div>
  );
};

export default SchedulePage;
