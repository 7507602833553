import React, { useContext, memo } from "react";
import Candidate from "../../assets/images/img-avatar.png";
import { Image } from "react-bootstrap";
import CrownIcon from "mdi-react/CrownIcon";
import { ResultContext } from "./ResultContext";
import ResultExport from "./ResultExport";
import PopupD from "../PopupD";
import { store } from "../../store/store";
import { useEffect } from "react";
import { useState } from "react";

const ResultCard = () => {
  const resultModel = useContext(ResultContext);
  const globalState = useContext(store);
  const [totalVotes, setTotalVotes] = useState([]);
  const {
    electionResults,
    onResultVerified,
    verifiedResults,
    toggleVerify,
    showPopup,
    setShowPopup,
    message,
    onPublishClick,
    startResultBtnStatus,
    startResultCalculation,
    spinner,
  } = resultModel;

  useEffect(() => {
    // if (electionResults) {
    //   // console.log(electionResults)
    //   electionResults.map((item, index) => {
    //     let votesSum = 0;
    //     let positionName = item.item.results.map((result, index) => {
    //       votesSum = votesSum + result.voteCount;
    //     });
    //     setTotalVotes([...totalVotes, votesSum]);
    //     console.log(votesSum);
    //     console.log(totalVotes);
    //   });
    // }
  }, [electionResults,startResultCalculation]);

  return (
    <div>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="row">
            <div className="col-md-12 grid-margin">
              <div className="d-flex justify-content-between flex-wrap">
                <div className="d-flex align-items-center dashboard-header flex-wrap mb-3 mb-sm-0">
                  <h4 className="mr-4 mb-0 font-weight-bold">Results</h4>
                </div>
                {false && <ResultExport />}
              </div>
            </div>
          </div>
          {electionResults &&
            electionResults.map((e, key) => {
              let totalVotesCount = 0;
              let candidate_list = e.results
                .sort((a, b) =>
                  a.nomination.name < b.nomination.name ? 1 : -1
                )
                .sort((a, b) => (a.voteCount < b.voteCount ? 1 : -1));
              let verifiedList = verifiedResults.filter(
                (x) => x.positionId === e._id
              )[0];
              return (
                <div key={key} className="card margin5">
                  <div className="card-body">
                    <div className="d-flex align-items-start justify-content-between">
                      <p className="card-title flex-grow">
                        {e.association_position
                          ? e.association_position.name
                          : ""}
                      </p>
                      {verifiedList && verifiedList.verifyCount < 3 && (
                        <button
                          type="button"
                          className="btn btn-danger btn-rounded tab-btn1"
                        >
                          {"Awaiting Reviewer " +
                            (verifiedList.verifyCount + 1) +
                            " verification"}
                        </button>
                      )}
                      {verifiedList &&
                        verifiedList.verifyCount >= 3 &&
                        globalState.state.lockResult &&
                        !e?.isPublished && (
                          <button
                            onClick={() => onPublishClick(e._id)}
                            className="btn btn-danger pull-right"
                          >
                            Publish Result
                          </button>
                        )}
                    </div>
                    <div className="scrollbar-primary grey-height-over">
                      <div className="table-responsive mt-3 ">
                        <table className="table badge-tab">
                          <tbody>
                            {candidate_list.map((x, index) => {
                              totalVotesCount = totalVotesCount + x.voteCount;
                              return (
                                <tr
                                  key={index}
                                  onClick={() =>
                                    toggleVerify(
                                      e._id,
                                      x.nominationId,
                                      e?.association_position?.totalPositions
                                        ? e.association_position.totalPositions
                                        : 0,
                                      verifiedList.haveWinner,
                                      verifiedList.verifyCount,
                                      e?.association_position?.name
                                        ? e.association_position.name
                                        : "",
                                      verifiedList.verified
                                    )
                                  }
                                  className={
                                    (x.isWinner
                                      ? "tab "
                                      : verifiedList?.nominations &&
                                        verifiedList?.nominations.indexOf(
                                          x.nominationId
                                        ) !== -1 &&
                                        verifiedList.haveWinner === false
                                        ? "tab "
                                        : "") +
                                    (verifiedList &&
                                      verifiedList.verified === false
                                      ? "cursotpointer"
                                      : "")
                                  }
                                >
                                  <td className="py-1 pl-4">
                                    <div className="d-flex align-items-center">
                                      {x.isWinner && (
                                        <div className="win-badge">
                                          <CrownIcon size={14} />
                                        </div>
                                      )}
                                      <Image
                                        src={
                                          x.nomination &&
                                            x.nomination.profileImage
                                            ? x.nomination.profileImage
                                            : Candidate
                                        }
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = Candidate;
                                        }}
                                        alt=""
                                      />
                                      <div className="ml-3">
                                        <p className="mb-0 txt-bold">
                                          {x.nomination && x.nomination.name
                                            ? x.nomination.name
                                            : ""}
                                        </p>
                                        <p className="mb-0  text-small">
                                          {x.nomination && x.nomination.pfNumber
                                            ? "PF#: " + x.nomination.pfNumber
                                            : ""}
                                        </p>
                                        <p className="mb-0  text-small">
                                          {x.nomination &&
                                            x.nomination.primaryContactNumber
                                            ? x.nomination.primaryContactNumber
                                            : ""}
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <span className="d-flex justify-content-end txt-bold">
                                      {x.voteCount}
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* {verifiedList && verifiedList.verified === false && ( */}
                    <div className="d-flex">
                      <div className="mt-3 font-weight-bold">
                        TOTAL VOTES :{totalVotesCount}
                      </div>
                      {
                        verifiedList && verifiedList.verified === false && <>
                          <button
                            disabled={
                              e.association_position &&
                                e.association_position.totalPositions &&
                                verifiedList &&
                                verifiedList.nominations &&
                                ((candidate_list.length <
                                  e.association_position.totalPositions &&
                                  candidate_list.length ===
                                  verifiedList.nominations.length) ||
                                  verifiedList.nominations.length ===
                                  e.association_position.totalPositions)
                                ? false
                                : true
                            }
                            type="button"
                            className="btn btn-danger mt-3 mr-2 btn-r subBtn ml-auto"
                            onClick={() =>
                              document.getElementById(e.association_position._id)
                                .checked
                                ? onResultVerified(e.association_position._id)
                                : void 0
                            }
                          >
                            Submit
                          </button>
                          <span className="verifyTxt">
                            <label>
                              <input
                                id={
                                  e.association_position
                                    ? e.association_position._id
                                    : ""
                                }
                                type="checkbox"
                                className="mt-3"
                              />
                              <span className="ml-2 mr-4">Verified</span>
                            </label>
                          </span>
                        </>
                      }

                    </div>
                    {/* )} */}
                  </div>
                </div>
              );
            })}
          {electionResults && electionResults.length === 0 && (
            <div id="notfounddiv">
              <div className="notfound">
                {spinner && (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                )}
                {!spinner &&
                  (startResultBtnStatus ? (
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={startResultCalculation}
                    >
                      Start Result
                    </button>
                  ) : (
                    <p>Results are not published yet.</p>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <PopupD
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        isSuccess={false}
        message={message}
        isWarning={true}
      />
    </div>
  );
};

export default memo(ResultCard);
