const PositionColumns = [
  {
    dataField: "name",
    text: "Position",
    sort: true,
    headerClasses: 'tableHeader'
  },
  {
    dataField: "totalPositions",
    text: "Count",
    sort: true,
    headerClasses: 'tableHeader'
  },
  {
    dataField: "targetNomineeZone.name",
    text: "Target Zone",
    sort: true,
    headerFormatter: (column, colIndex,{sortElement}) => (
      <div style={{display:"inline-flex"}}>
      <div style={{marginRight:10}}>
          <div>{ column.text }</div>
          <div className="subheaderTable">{"(Who can file nominations)"}</div>
      </div>
      {sortElement}
      </div>
    ),
    headerClasses: 'tableHeader'
  },
  {
    dataField: "targetVotersZone.name",
    text: `Target Voters Zone`,
    sort: true,
    headerFormatter: (column, colIndex,{sortElement}) => (
      <div style={{display:"inline-flex"}}>
      <div style={{marginRight:10}}>
          <div>{ column.text }</div>
          <div className="subheaderTable">{"(Who can vote)"}</div>
      </div>
      {sortElement}
      </div>
    ),
    headerClasses: 'tableHeader'
  },
  {
    dataField: "action",
    text: "Action",
    headerClasses: 'tableHeader'
  },
];
  
  export default PositionColumns;