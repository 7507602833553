import React from 'react';
import UserProfileCard from '../components/UserProfile/UserProfileCard';
import Sidebar from '../components/Sidebar';
import { UserProfileContextProvider } from "../components/UserProfile/UserProfileContext";

const MemberPage = () => {
    return <div>
      <UserProfileContextProvider>
        <Sidebar />
        <UserProfileCard />
      </UserProfileContextProvider>     
    </div>;
}

export default MemberPage;