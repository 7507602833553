import React from "react";
import Sidebar from "../components/Sidebar";
import Login from "../components/Login/Login";
import { LoginContextProvider } from "../components/Login/LoginContext";

const LoginPage = () => {
  return (
    <div>
      {/* <Navbar /> */}
      <LoginContextProvider>
        <Login />
        <Sidebar />
      </LoginContextProvider>
    </div>
  );
};

export default LoginPage;
