import React, { createContext, useReducer } from "react";
import { USER_LOGIN,TOKEN,IS_CHIEF_ELECTORAL_OFFICER,USER,WITHDRAW_NOMINATIONS,DECLARE_RESULT,LOCK_RESULTS,PUBLISH_CANDIDATE_LIST,VOTING,VOTING_ENABLECOUNT,ONBOARD_NEW_COMMITTEE,ONBOARD_NEW_COMMITTEE_ENABLECOUNT } from "./types";

const initialState = {
  authLogin: true,
  token:"",
  isChiefElectoralOfficer:false,
  user:[],
  withdrawNominations:true,
  declareResult:true,
  lockResult:false,
  publishCandidateList:false,
  voting:false,
  votingEnableCount:0,
  onboardEnableCount:0,
  onboard:false
};

const store = createContext(initialState);
const { Provider } = store;

const reducer = (state, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        authLogin: action.payload,
      };
    case TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case IS_CHIEF_ELECTORAL_OFFICER:
      return {
        ...state,
        isChiefElectoralOfficer: action.payload,
      };
    case USER:
      return {
        ...state,
        user: action.payload,
      };
    case WITHDRAW_NOMINATIONS:
      return {
        ...state,
        withdrawNominations: action.payload,
      };
    case DECLARE_RESULT:
      return {
        ...state,
        declareResult: action.payload,
      };
      case LOCK_RESULTS:
        return {
          ...state,
          lockResult: action.payload,
        };         
      case PUBLISH_CANDIDATE_LIST:
        return {
          ...state,
          publishCandidateList: action.payload,
        };  
      case VOTING:
        return {
          ...state,
          voting: action.payload
        };
      case VOTING_ENABLECOUNT:
        return {
          ...state,
          votingEnableCount: action.payload
        };
      case ONBOARD_NEW_COMMITTEE:
        return {
          ...state,
          onboard: action.payload
        };
      case ONBOARD_NEW_COMMITTEE_ENABLECOUNT:
        return {
          ...state,
          onboardEnableCount: action.payload
        };          
    default:
      return state;
  }
};

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
