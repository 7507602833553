import React, { useContext, memo } from "react";
import { useEffect } from "react";
import { StatsContext } from "./StatsContext";

const Stats = () => {
    const StatsModel = useContext(StatsContext);
    const { statsData, zonalPositionData } = StatsModel;

    return (
        <div>
            <div className="main-panel" >
                <div className="content-wrapper" >
                    <div className="row">
                        <div className="col-md-12 grid-margin" >
                            <div className="d-flex justify-content-between flex-wrap">
                                <div className="d-flex align-items-center dashboard-header flex-wrap mb-3 mb-sm-0">
                                    <h4 className="mr-4 mb-0 font-weight-bold">Statistics</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: '60vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div>
                            {statsData ? (
                                <p className="card-title flex-grow">
                                    <strong>
                                        <h4 className="mr-4 mb-2 font-weight-bold"> Total Members who have voted until now: {statsData}</h4>
                                    </strong>
                                    {zonalPositionData && zonalPositionData?.map((item, index) => {
                                        const nameFirst = item.association_position.name.split(",");
                                        if (nameFirst[0] === "Zonal Secretary") {
                                            return (
                                                <div style={{ display: "flex", paddingTop:'8px' , paddingLeft:'5px'}}>
                                                    <div>
                                                        <strong>
                                                            Zonal Secretary, {nameFirst[1]} -
                                                        </strong>
                                                    </div>
                                                    <div style={{paddingLeft:"4px"}}>
                                                        {item.totalPoll}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                    }

                                </p>
                            ) : (
                                <strong>
                                    <h4 className="mr-4 mb-2 font-weight-bold">No data available</h4>
                                </strong>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(Stats);
