import React, { useContext } from "react";
import routes from "./constants/routes.json";
import { useLocation, Route, Redirect } from "react-router-dom";
import { store } from "./store/store";

const PrivateRoute = (props) => {
  const globalState = useContext(store);
  //const token = localStorage.getItem("token");
  const token = globalState.state.token;
  const location = useLocation();

  return token!=="" ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: routes.LOGIN,
        state: { from: location },
      }}
    />
  );
};

export default PrivateRoute;
