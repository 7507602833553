import React, { createContext, useState, useEffect,useContext } from "react";
import API from "../../api/api";
import {WITHDRAW_NOMINATIONS,DECLARE_RESULT,LOCK_RESULTS,PUBLISH_CANDIDATE_LIST,VOTING,VOTING_ENABLECOUNT,ONBOARD_NEW_COMMITTEE,ONBOARD_NEW_COMMITTEE_ENABLECOUNT} from "../../store/types";
import {store} from "../../store/store";
import settings from "../../constants/settings";

export const SettingsContext = createContext();

export const SettingsContextProvider = ({ children }) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const [settingsList, setSettingsList] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const enableSettings = async (id, enabled,title) => {
    setSelectedId(id);
    setSelectedStatus(enabled);
    // setMessage((enabled?MEMBER_PROFILE_DISABLE_MSG:MEMBER_PROFILE_ENABLE_MSG)+" setting '"+title+ "', Are you sure?");
    setShowPopup(true);
    switch (title.trim()) {
      case settings.MEMBER_PROFILE_EDIT:
        setMessage(enabled?settings.MEMBER_PROFILE_DISABLE_MSG:settings.MEMBER_PROFILE_ENABLE_MSG);
        break
      case settings.SUBMIT_NOMINATIONS:
        setMessage(enabled?settings.SUBMIT_NOMINATIONS_DISABLE_MSG:settings.SUBMIT_NOMINATIONS_ENABLE_MSG);
        break
      case settings.WITHDRAW_NOMINATIONS:
        setMessage(enabled?settings.WITHDRAW_NOMINATIONS_DISABLE_MSG:settings.WITHDRAW_NOMINATIONS_ENABLE_MSG);
        break
      case settings.VOTING:
        setMessage(enabled?settings.VOTING_DISABLE_MSG:settings.VOTING_ENABLE_MSG);
        break
      case settings.LOCK_RESULTS:
        setMessage(enabled?settings.LOCK_RESULTS_DISABLE_MSG:settings.LOCK_RESULTS_ENABLE_MSG);
        break
      case settings.DECLARE_RESULTS:
        setMessage(enabled?settings.DECLARE_RESULTS_DISABLE_MSG:settings.DECLARE_RESULTS_ENABLE_MSG);
        break
      case settings.DECLARE_RESULT:
        setMessage(enabled?settings.DECLARE_RESULTS_DISABLE_MSG:settings.DECLARE_RESULTS_ENABLE_MSG);
        break
      case settings.ONBOARD_NEW_COMMITTEE:
        setMessage(enabled?settings.ONBOARD_NEW_COMMITTEE_DISABLE_MSG:settings.ONBOARD_NEW_COMMITTEE_ENABLE_MSG);
        break
      case settings.PUBLISH_CANDIDATE_LIST:
        setMessage(enabled?settings.PUBLISH_CANDIDATE_LIST_DISABLE_MSG:settings.PUBLISH_CANDIDATE_LIST_ENABLE_MSG);
        break  
      default:
        setMessage((enabled?"Disable ":"Enable ")+" setting '"+title+ "', Are you sure?");
      break
    }
  };

  const onConfirmClick=async()=>{
    API.updateSettings(selectedId, !selectedStatus,globalState.state.token).then(async () => {
      fetchSettings();
      setShowPopup(false);
      setMessage("");
      setSelectedId("");
      setSelectedStatus(false);
    });
  }

  const fetchSettings = async () => {
    API.getSettings(globalState.state.token)
      .then(async (response) => {
        setSettingsList(response.data.settings_list);
        response.data.settings_list.map((settingsData) => {
          switch (settingsData.title.trim()) {
            case settings.WITHDRAW_NOMINATIONS:
              dispatch({
                type: WITHDRAW_NOMINATIONS,
                payload: settingsData.isEnabled,
              });
              break;
            case settings.DECLARE_RESULT:
              dispatch({
                type: DECLARE_RESULT,
                payload: settingsData.isEnabled,
              });
              break;
            case settings.LOCK_RESULTS:
              dispatch({
                type: LOCK_RESULTS,
                payload: settingsData.isEnabled,
              });
              break;
            case settings.PUBLISH_CANDIDATE_LIST:
              dispatch({
                type: PUBLISH_CANDIDATE_LIST,
                payload: settingsData.isEnabled,
              });
              break;
            case settings.VOTING:
              dispatch({
                type: VOTING,
                payload: settingsData.isEnabled,
              });
              dispatch({
                type: VOTING_ENABLECOUNT,
                payload: settingsData.enabledCount,
              });
              break;
            case settings.ONBOARD_NEW_COMMITTEE:
              dispatch({
                type: ONBOARD_NEW_COMMITTEE,
                payload: settingsData.isEnabled,
              });
              dispatch({
                type: ONBOARD_NEW_COMMITTEE_ENABLECOUNT,
                payload: settingsData.enabledCount,
              });
              break;    
            default:
              break
            
          }
        });
      })
      .catch((e) => {
        console.log("error of commision list", e);
      });
  };

  useEffect(async () => {
    fetchSettings();
    localStorage.setItem("statusFilter", "");
    localStorage.setItem("positionFilter", "");
  }, []);

  const providerValue = {
    settingsList,

    setSettingsList,
    enableSettings,
    showPopup,
    setShowPopup,
    isSuccess,
    onConfirmClick,
    message
  };

  return (
    <SettingsContext.Provider value={providerValue}>
      {children}
    </SettingsContext.Provider>
  );
};
