import React, { createContext, useState, useContext, useEffect } from "react";
import api from "../../api/api";
import { store } from "../../store/store";

export const StatsContext = createContext();

export const StatsContextProvider = ({ children }) => {
    const [statsData, setStatsData] = useState(0);
    const [zonalPositionData, setZonalPositionsData] = useState([]);
    const globalState = useContext(store);

    useEffect(() => {
        const fetchPoll = () => {
            api.getStatsData(globalState.state.token).then((response) => {
                if (response?.data?.statistics?.total_poll) {
                    setStatsData(response.data.statistics.total_poll);
                    console.log("zonal data", response.data.statistics.positions)
                    setZonalPositionsData(response.data.statistics.positions);
                }
            }).catch((e) => { });
        }
        fetchPoll();
        // const interval = setInterval(() => fetchPoll(), 1000)
        // return () => {
        //     clearInterval(interval);
        // }
    }, []);

    const providerValue = {
        statsData,
        zonalPositionData
    };

    return (
        <StatsContext.Provider value={providerValue}>
            {children}
        </StatsContext.Provider>
    )

}