import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import API from "../../api/api";
import PencilIcon from "mdi-react/PencilIcon";
import Moment  from 'react-moment';
import {store} from "../../store/store";

export const ScheduleContext = createContext();

const columns = [
  {
    dataField: "description",
    text: "Stage Description",
    sort: true,
    formatter:(cellContent, row)=>(
      <div>
      {row.name &&
      <div className="font-weight-bold">{row.name}</div >
      }
      {row.description &&
      <div>{row.description}</div >
      }
      </div>
    ),
    headerClasses: 'tableHeader',
    style: {
      maxWidth: '600px',
      whiteSpace: 'pre-line',
      overflowWrap: 'anywhere'
    }
  },
  {
    dataField: "startAt",
    text: "Start Date/Time",
    sort: true,
    formatter:(cellContent, row)=>(
      <div>
      {row.startAt &&
      <Moment date={new Date(row.startAt)} format="DD/MM/YYYY HH:mm"></Moment >
      }
      </div>
    ),
    headerClasses: 'tableHeader'
  },
  {
    dataField: "endAt",
    text: "End Date/Time",
    sort: true,
    formatter:(cellContent, row)=>(
      <div>
      {row.endAt &&
      <Moment date={new Date(row.endAt)} format="DD/MM/YYYY HH:mm"></Moment >
      }
      </div>
    ),
    headerClasses: 'tableHeader'
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    headerClasses: 'tableHeader'
  },
  {
    dataField: "action",
    text: "Action",
    headerClasses: 'tableHeader'
  },
  {
    dataField: "name",
    text: "Actinameon",
    hidden: true
  },
];

export const ScheduleContextProvider = ({ children }) => {
  const [stages, setStages] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [editButtonClick, setEditButtonClick] = useState(false);
  const [addNewStateClick, setAddNewStageClick] = useState(false);
  const [columnData, setColumnData] = useState(columns);
  const [show, setShow] = useState(false);
  const [stageName, setStageName] = useState("");
  const [stageDescription, setStageDescription] = useState("");
  const [saveTrigger, setSaveTrigger] = useState(false);
  const [editItem, setEditItem] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [announceItem, setAnnounceItem] = useState("");
  const [stageNameValidation, setStageNameValidation] = useState(false);
  const [stageDescriptionValidation, setStageDescriptionValidation] = useState(false);
  const globalState = useContext(store);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setStageDescriptionValidation(false);
    setStageNameValidation(false);
    setEditItem(false);
  };

  const resetButtonClicks = () => {
    setEditButtonClick(false);
    setAddNewStageClick(false);
  };

  const saveData = () => {
    if(stageName===""){
      setStageNameValidation(true);
    }
    if(stageDescription===""){
      setStageDescriptionValidation(true);
    }
    if(stageName !== "" && stageDescription !== ""){
      setSaveTrigger(true);
    }
  };

  const clearTheInputDate = () => {
    setStageName("");
    setStageDescription("");
    setStartDate(new Date());
    setEndDate(new Date());
  };

  const editStageItem = (item) => {
    setSelectedId(item._id);
    setEditItem(true);
    setStageName(item.name);
    setStageDescription(item.description);
    setStartDate(item.startAt);
    setEndDate(item.endAt);
    setShow(true);
  };

  const announceStageItem = (item) => {};

  const changeObjStructure = async (data) => {
    const actionIncluded = await data.map((item) => ({
      ...item,
      action: (
        <div>
          {item.status !=="Completed" &&
        <div className="d-flex align-items-center ml-3">
          <div className="icon-square-sm-success hover-cursor align-items-center d-flex justify-content-center">
            <PencilIcon
              size={16}
              className="text-success"
              onClick={() => editStageItem(item)}
            />
          </div>
          {false &&
          <button
            className="btn btn-danger tab-btn ml-4"
            onClick={() => announceStageItem(item)}
          >
            Notify/Announce
          </button>
          }
          </div>
          }
        </div>
      ),
    }));
    return actionIncluded;
  };

  const getStagesFromApi = async () => {
    API.getStages(globalState.state.token)
      .then(async (response) => {
        const data = await changeObjStructure(response.data.stage_list);
        setStages(data);
      })
      .catch((e) => {
        console.log("error of commision list", e);
      });    
  };

  const onEndDatechange=(date)=>{
    if(date <  new Date(startDate)){
      setStartDate(date);
    }
    setEndDate(date);
  }

  const onStartDatechange=(date)=>{
    if(date > new Date(endDate)){
      setEndDate(date);
    }
    setStartDate(date);
  }

  useEffect(() => {}, [addNewStateClick]);

  useEffect(async () => {
    getStagesFromApi();
    localStorage.setItem("statusFilter", "");
    localStorage.setItem("positionFilter", "");
  }, []);

  useEffect(async () => {
    if (
      addNewStateClick &&
      stageName &&
      stageDescription &&
      startDate &&
      endDate
    ) {
      try {
        await API.addStage(stageName, stageDescription, startDate, endDate,globalState.state.token);
        getStagesFromApi();
        setShow(false);
      } catch (err) {
        console.log("add stage error", err);
      }
    } else if (
      editItem &&
      stageName &&
      stageDescription &&
      startDate &&
      endDate &&
      selectedId
    ) {
      try {
        await API.editState(
          selectedId,
          stageName,
          stageDescription,
          startDate,
          endDate,
          globalState.state.token
        );
        setShow(false);
        getStagesFromApi();
      } catch (err) {
        console.log("add stage error", err);
      }
    }
    resetButtonClicks();
    setSaveTrigger(false);
  }, [saveTrigger]);

  const providerValue = {
    stages,
    startDate,
    endDate,
    columnData,
    show,
    stageName,
    editButtonClick,
    addNewStateClick,
    stageDescription,

    setStages,
    setStartDate,
    setEndDate,
    setColumnData,
    setShow,
    handleClose,
    handleShow,
    setStageName,
    setEditButtonClick,
    setAddNewStageClick,
    setStageDescription,
    setSaveTrigger,
    saveData,
    clearTheInputDate,
    onEndDatechange,
    onStartDatechange,
    stageNameValidation,
    setStageNameValidation,
    stageDescriptionValidation,
    setStageDescriptionValidation,
    editItem
  };

  return (
    <ScheduleContext.Provider value={providerValue}>
      {children}
    </ScheduleContext.Provider>
  );
};
