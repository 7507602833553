import React from "react";
import Stats from "../components/Stats/Stats";
import { StatsContextProvider } from "../components/Stats/StatsContext";
import Sidebar from "../components/Sidebar";

const ResultPage = () => {
    return (
        <div>
            <StatsContextProvider>
                <Sidebar />
                <Stats />
            </StatsContextProvider>
        </div>
    );
};

export default ResultPage;
