import React from "react";
import ResultCard from "../components/Result/ResultCard";
import {ResultContextProvider} from "../components/Result/ResultContext";
import Sidebar from "../components/Sidebar";

const ResultPage = () => {
  return (
    <div>
       <ResultContextProvider>
        <Sidebar />
       <ResultCard />
      </ResultContextProvider>
    </div>
  );
};

export default ResultPage;
