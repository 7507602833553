import React, {createContext,useState,useEffect,useContext} from "react";
import API from "../../api/api";
import EyeIcon from "mdi-react/EyeIcon"
import Moment  from 'react-moment';
import { useHistory } from "react-router-dom";
import routes from '../../constants/routes.json';
import {store} from "../../store/store";
import settings from '../../constants/settings.json';
  
export const NominationContext = createContext();
  export const NominationContextProvider = ({ children }) => {
    let history = useHistory();
    const globalState = useContext(store);
    const columns = [
      {
        dataField: "association.name",
        text: "Position",
        sort: true,
        style: {
          fontWeight: 'bold'
        },
        headerClasses: 'tableHeader'
      },
      {
        dataField: "name",
        text: "Name",
        sort: true,
        formatter: (cellContent, row) => (
          <div>
              <div>{row.name}</div>
              {row.mobile &&
                  <div>{row.mobile}</div>
              }
              {row.pfNumber &&
              <div>{'PF#: '+row.pfNumber}</div>
              }
          </div>
        ),
        headerClasses: 'tableHeader'
      },
      {
        dataField: "email",
        text: "Email",
        sort: true,
        headerClasses: 'tableHeader'
      },
      {
        dataField: "branch",
        text: "Branch & Address",
        sort: true,
        formatter:(cellContent, row)=>(
          <div>
            {row.designation &&
              <div>{row.designation}</div>
            }
              <div className={row.branch!==""||row.address!==""?"display-block":"display-none"}>{row.branch+ (row.branch!==""?' Branch':"")+(row.branch && row.branch!=="" && row.address!==""?", ":"")}<span  className={row.address!==""?"display-block":"display-none"}>{row.address}</span></div>
          </div>
        ),
        headerClasses: 'tableHeader',
        style: {
          maxWidth: '250px',
          whiteSpace: 'pre-line',
          overflowWrap: 'anywhere'
        }
      },
      {
        dataField: "status",
        text: "Status",
        formatter:(cellContent, row)=>(
              <div>{row.status.toLowerCase()==="withdraw"?"Withdrawn":row.status}</div>
        ),
        sort: true,
        headerClasses: 'tableHeader'
      },
      {
        dataField: "createdAt",
        text: "Timestamp",
        sort: true,
        formatter:(cellContent, row)=>(
          <div>
          {row.createdAt &&
          <Moment date={new Date(row.createdAt)} format="MMMM DD, yyyy HH:mm"></Moment >
          }
          </div>
        ),
        headerClasses: 'tableHeader'
      },
      {
        dataField: "action",
        text: "Action",
        formatter:(cellContent, row)=>(
          <div className="d-flex align-items-center ml-3">
              <div className="icon-square-sm-warning hover-cursor align-items-center d-flex justify-content-center" onClick={()=>viewNominationItem(row)}>
                  <EyeIcon size={16} className="text-warning"/>
              </div>
        </div>
        ),
        headerClasses: 'tableHeader',
        style: {
          maxWidth: '50px'
        }
      },
      {
        dataField: "_id",
        text: "_id",
        hidden: true
      },
      {
        dataField: "pfNumber",
        text: "pfNumber",
        hidden: true
      },
      {
        dataField: "branch",
        text: "branch",
        hidden: true
      },
      {
        dataField: "designation",
        text: "designation",
        hidden: true
      },
    ];

    const [nominations, setNominations] = useState([]);
    const [nominationsfiltered, setNominationsFiltered] = useState([]);
    const [positions, setPositions] = useState([]);
    const [status, setStatus] = useState([]);
    const [viewButtonClick, setViewButtonClick] = useState(false);
    const [columnData, setColumnData] = useState(columns);
    const [selectedId, setSelectedId] = useState("");
    const [selectedPosition, setSelectedPosition] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(settings.DEFAULT_PAGE_SIZE);
    const [multipleNominationSwitch,setMultipleNominationSwitch] = useState(false);
    const [rejectNomination, setRejectNominationClick] = useState(false);
    const [rejectionNote, setRejectionNote] = useState("Rejected because of multiple nominations.");
    const [showMandatoryValidation, setShowMandatoryValidation] = useState(false);
    const [showRejectAll, setShowRejectAll] = useState(false);

    const onSelectStatus = (val) => {
      setSelectedStatus(val);
      FilterNominations(selectedPosition,val,nominations);
      localStorage.setItem("statusFilter", val);
    };
    const onSelectPosition = (val) => {
      setSelectedPosition(val);
      FilterNominations(val,selectedStatus,nominations);
      localStorage.setItem("positionFilter", val);
    };
    const FilterNominations = (pos,st,nominationsList) => {
      let nominationsData;
      nominationsData = nominationsList.filter( x => ((pos===""?true: x.positionId ===pos) && (st===""? true: x.status===st)));
      setNominationsFiltered(nominationsData);
    }
    const getPositionsList = async () => {
      API.getAssociationPosition(globalState.state.token)
        .then(async (response) => {
          const data = response.data.association_list;
          setPositions(data);
        })
        .catch((e) => {
          console.log("error of position list", e);
        });
    };
    const getNominationsFromApi = async (Page,sizePerP) => {
      API.getNominations(globalState.state.token)
        .then(async (response) => {
          const data = response.data.nomination_list.sort((a, b) => new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1);
          setTotalRows(response.data.pagination.total_items);
          setCurrentPage(1);
          setTotalPages(response.data.pagination.total_pages);
          let statusobj = {};
          let status = [];
          let statustemp = [];
          data.map((item, index) => {
            if (item.status!==undefined && item.status!=="" && statustemp.indexOf(item.status) === -1) {
              statusobj.name=item.status;
              statusobj.value=item.status;
              status.push(statusobj);
              statustemp.push(item.status)
              statusobj={};
            }
          })
          setNominations(data);
          setNominationsFiltered(data);
          setStatus(status);
          if(localStorage.getItem('positionFilter')!=="" || localStorage.getItem('statusFilter')!=""){
            if(status.findIndex(x=>x.name=== localStorage.getItem('statusFilter'))===-1){
              localStorage.setItem("statusFilter", "");
            }
          setSelectedStatus(localStorage.getItem('statusFilter'));
          setSelectedPosition(localStorage.getItem('positionFilter'));
          FilterNominations(localStorage.getItem('positionFilter')?localStorage.getItem('positionFilter'):"",localStorage.getItem('statusFilter')?localStorage.getItem('statusFilter'):"",data);
          }
        })
        .catch((e) => {
          console.log("error of Nominations list", e);
        });
    };
    const getMultipleNominationsFromApi = async () => {
      API.getMultipleNominations(globalState.state.token)
        .then(async (response) => {
          const data = response.data.nomination_list.sort((a, b) => new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1);
          setTotalRows(response.data.pagination.total_items);
          setCurrentPage(1);
          setNominations(data);
          setNominationsFiltered(data);
        })
        .catch((e) => {
          console.log("error of Multiple Nominations list", e);
        });
    };
    const rejectMultipleNominationsFromApi = async (memeberid) => {
      API.rejectMultipleNominations(globalState.state.token,memeberid,rejectionNote)
        .then(async () => {
          getMultipleNominationsFromApi();
        })
        .catch((e) => {
          console.log("error of Multiple Nominations list", e);
        });
    };
    const viewNominationItem = (row) => {
        history.push({
          pathname:routes.NOMINATIONDETAIL_PAGE,
          state: { selectedId: row._id }
        });
      };

    const onRejectAllClick = () => {
      setRejectNominationClick(true);
    };

    const onRejected = () => {
      if(rejectionNote.trim() !== ""){
        let memberIds=[];
        nominations.map((item, index) => {
        if(memberIds.indexOf(item.memberId) === -1){
          rejectMultipleNominationsFromApi(item.memberId);
          memberIds.push(item.memberId)
        }
        });
        setRejectNominationClick(false);
        setRejectionNote("Rejected because of multiple nominations.")
      }
      else{
        setShowMandatoryValidation(true);
      }
    };

    const fetchSettings = async () => {
      API.getSettings(globalState.state.token)
        .then(async (response) => {
          const SettingsList = response.data.settings_list.filter(x=>x.title.trim()===settings.WITHDRAW_NOMINATIONS);
          if(SettingsList && SettingsList.length>0 && SettingsList[0].buttonStatus===0 && SettingsList[0].enabledCount===1){
            setShowRejectAll(true);
          }
          else{
            setShowRejectAll(false);
          }
        })
        .catch((e) => {
          console.log("error of commision list", e);
        });
    };

    useEffect(async () => {
      getNominationsFromApi(1,settings.DEFAULT_PAGE_SIZE);
      getPositionsList();
      fetchSettings();
    }, []);
    
    useEffect(async () => {
      if(multipleNominationSwitch===false){
      getNominationsFromApi(1,settings.DEFAULT_PAGE_SIZE);
      }
      else{
        getMultipleNominationsFromApi();
        localStorage.setItem("statusFilter", "");
        localStorage.setItem("positionFilter", "");
        setSelectedStatus("");
        setSelectedPosition("");
      }
    }, [multipleNominationSwitch]);
  
    const providerValue = {
      nominations,
      nominationsfiltered,
      columnData,
      selectedId,
      positions,
      status,
      selectedPosition,
      selectedStatus,
      viewButtonClick,
      setNominations,
      setColumnData,
      setViewButtonClick,
      setSelectedId,
      setPositions,
      setStatus,
      setSelectedPosition,
      setSelectedStatus,
      onSelectStatus,
      onSelectPosition,
      setNominationsFiltered,
      setTotalPages,
      setCurrentPage,
      setSizePerPage,
      sizePerPage,
      currentPage,
      totalPages,
      getNominationsFromApi,
      totalRows, 
      setTotalRows,
      setMultipleNominationSwitch,
      multipleNominationSwitch,
      onRejectAllClick,
      rejectNomination,
      rejectionNote,
      setRejectionNote,
      setRejectNominationClick,
      onRejected,
      showMandatoryValidation,
      setShowMandatoryValidation,
      showRejectAll
    };
  
    return (
      <NominationContext.Provider value={providerValue}>
        {children}
      </NominationContext.Provider>
    );
  };
  