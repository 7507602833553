import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Icons from './Icons'

  const PopupD = ({
    showPopup,
    setShowPopup,
    isSuccess,
    onConfirmClick,
    message,
    isWarning
  }) => {

  return (
<Modal className="home-modals" show={showPopup} >
    <Modal.Header className="contnt-center" closeButton onClick={()=>setShowPopup(false)}>
    <div className="check-circle-img">{isSuccess?Icons.successTick:Icons.warning}</div>
        <Modal.Title className="popupmsg" >{message}</Modal.Title>
    </Modal.Header>
    { (isSuccess || isWarning) &&
    <Modal.Footer className="d-flex justify-content-center popupfooter">
        <Button variant="primary" onClick={()=>setShowPopup(false)}>Ok</Button>
    </Modal.Footer>
    }
    { !isSuccess&& !isWarning &&
     <Modal.Footer className="popupfooter">
     <p className="text-primary btn mt-2 cursotpointer btnMargin" onClick={()=>setShowPopup(false)}>
       Cancel
     </p>
     <Button
       classBame="submit-btn-orange"
       variant="primary"
       onClick={() => {
         onConfirmClick();
       }}
     >
       {"Confirm"}
     </Button>
   </Modal.Footer>
    }

</Modal>
);
};

export default PopupD;
