import React, { memo, useContext } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import PopupA from "../PopupA";
import { ScheduleContext } from "./TableCardContext";

const TableCard = () => {
  const scheduleModel = useContext(ScheduleContext);
  const {
    stages,
    startDate,
    endDate,
    columnData,
    show,
    stageName,
    stageDescription,
    setStartDate,
    setEndDate,
    handleClose,
    handleShow,
    setStageName,
    setAddNewStageClick,
    setStageDescription,
    saveData,
    clearTheInputDate,
    onEndDatechange,
    onStartDatechange,
    stageNameValidation,
    setStageNameValidation,
    stageDescriptionValidation,
    setStageDescriptionValidation,
    setShow,
    editItem
  } = scheduleModel;

  const { SearchBar } = Search;
  const rowClasses = row => (row.status==="Pending" ? "pendingRow" : (row.status==="Completed"?"completedRow":"inprogressRow"));
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: "Last",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });

  return (
    <div>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="row">
            <div className="col-md-12 grid-margin">
              <div className="d-flex justify-content-between flex-wrap">
                <div className="d-flex align-items-center dashboard-header flex-wrap mb-3 mb-sm-0">
                  <h4 className="mr-4 mb-0 font-weight-bold">Schedule</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-start justify-content-between">
                    <p className="card-title flex-grow">Stages</p>
                    <button
                      className="btn btn-primary pull-right"
                      type="button"
                      onClick={() => {
                        setAddNewStageClick(true);
                        clearTheInputDate();
                        handleShow();
                      }}
                    >
                      Add new Stage
                    </button>
                  </div>
                  <ToolkitProvider
                    keyField="id"
                    data={stages}
                    columns={columnData}
                    search
                  >
                    {(props) => (
                      <div>
                        <div className="d-flex align-items-center justify-content-between mt-3">
                          <span className="srch-box">
                            <label className="mr-2">Search:</label>
                            <SearchBar
                              {...props.searchProps}
                              className="inpt-box"
                            />
                          </span>
                        </div>

                        <BootstrapTable
                          {...props.baseProps}
                          pagination={pagination}
                          rowClasses={rowClasses}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/****************** Modal Popup *********************/}
      <PopupA
        handleClose={handleClose}
        title={editItem?"Edit Stage":"Add New Stage"}
        show={show}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        startDate={startDate}
        endDate={endDate}
        dropListTitle={"Stage name"}
        inputValue={setStageName}
        selectedItem={stageName}
        descriptionTitle={"Stage Description"}
        setDescription={setStageDescription}
        description={stageDescription}
        saveData={saveData}
        onEndDatechange={onEndDatechange}
        onStartDatechange={onStartDatechange}
        stageNameValidation={stageNameValidation}
        setStageNameValidation={setStageNameValidation}
        stageDescriptionValidation={stageDescriptionValidation}
        setStageDescriptionValidation={setStageDescriptionValidation}
        setShow={setShow}
        editItem={editItem}
      />
    </div>
  );
};

export default memo(TableCard);
