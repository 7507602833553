import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import routes from "./constants/routes.json";
import LoginPage from "./containers/LoginPage";
import SchedulePage from "./containers/SchedulePage";
import ResultPage from "./containers/resultPage";
import SettingsPage from "./containers/SettingsPage";
import NotificationPage from "./containers/NotificationPage";
import PositionPage from "./containers/PositionPage";
import NominationPage from "./containers/NominationPage";
import NominationDetailPage from "./containers/NominationDetailPage";
import PublishPage from "./containers/PublishPage";
import MemberPage from "./containers/MemberPage";
import PrivateRoute from "./PrivateRoute";
import StatsPage from "./containers/StatsPage";
import NotFound from "./containers/NotFound/NotFound";
import { useEffect } from "react";
//TODO: CHANGE LOGIN PATH TO DEFAULT

const App = () => {
  const env = process.env.REACT_APP_ENV;

  const preventRightClick = () => {
    // disable right click
    document.addEventListener("contextmenu", (event) => event.preventDefault());

    document.onkeydown = function (e) {
      console.log(e)
      // disable F12 key
      if (e.keyCode == 123) {
        return false;
      }

      // disable I key
      if (e.ctrlKey && e.shiftKey && e.keyCode == 73) {
        return false;
      }

      // disable J key
      if (e.ctrlKey && e.shiftKey && e.keyCode == 74) {
        return false;
      }

      // disable U key
      if (e.ctrlKey && e.keyCode == 85) {
        return false;
      }
    };
  };

  useEffect(() => {
    if (env !== "development") preventRightClick();
  }, []);

  return (
    <div
      className={"App " + (env !== "development" ? "no_selection" : "")}
      onContextMenu={(e) =>
        env !== "development" ? e.preventDefault() : void 0
      }
      onContextMenu="return false;"
    >
      <BrowserRouter>
        <Switch>
          <Route path={routes.LOGIN} component={LoginPage} exact />
          <PrivateRoute
            path={routes.SCHEDULE_PAGE}
            component={SchedulePage}
            exact
          />
          <PrivateRoute path={routes.STATS_PAGE} component={StatsPage} exact />
          <PrivateRoute
            path={routes.RESULT_PAGE}
            component={ResultPage}
            exact
          />
          <PrivateRoute
            path={routes.SETTINGS_PAGE}
            component={SettingsPage}
            exact
          />
          <PrivateRoute
            path={routes.NOTIFICATION_PAGE}
            component={NotificationPage}
            exact
          />
          <PrivateRoute
            path={routes.POSITION_PAGE}
            component={PositionPage}
            exact
          />
          <PrivateRoute
            path={routes.NOMINATION_PAGE}
            component={NominationPage}
            exact
          />
          <PrivateRoute
            path={routes.NOMINATIONDETAIL_PAGE}
            component={NominationDetailPage}
            exact
          />
          <PrivateRoute
            path={routes.PUBLISH_PAGE}
            component={PublishPage}
            exact
          />
          <PrivateRoute
            path={routes.MEMBERPROFILE}
            component={MemberPage}
            exact
          />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
