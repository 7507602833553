import React,{ useContext } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import {NominationDetailsContext} from './NominationDetailsContext';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const NominationRejectPopup = ({
  rejectNomination,
  rejectionNote,
  setRejectionNote,
  setRejectNominationClick,
  onRejected,
  showMandatoryValidation,
  setShowMandatoryValidation,
  buttonName
}) => {
  return (
    <Modal show={rejectNomination}>
        <Modal.Header closeButton onClick={()=>setRejectNominationClick(false)}>
          <Modal.Title><h5>Comment</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body> <form>
           
           <div className="form-group">
             <label for="message-text">
               Reason for rejection <span className="reqStar">*</span>
             </label>
             <textarea
               className={(showMandatoryValidation?"border-validation ":"")+"form-control inputDec"}
               rows="5"
               placeholder="type here"
               value={rejectionNote}
               onChange={({ target: { value } }) =>setRejectionNote(value)}
               onFocus={()=>setShowMandatoryValidation(false)}
             ></textarea>
           </div>
         
         </form></Modal.Body>
        <Modal.Footer>
            <div className="text-primary btn mt-2" onClick={()=>(setRejectNominationClick(false),setShowMandatoryValidation(false))}>
            Cancel
          </div>
          <Button variant="primary" onClick={()=>onRejected()}>
            {buttonName}
          </Button>
        </Modal.Footer>
      </Modal>);
};

export default NominationRejectPopup;
