import React, {createContext,useState,useEffect,useContext} from "react";
import API from "../../api/api";
import { useHistory,useLocation } from "react-router-dom";
import Moment  from 'react-moment';
import routes from '../../constants/routes.json';
import {store} from "../../store/store";
  
export const NominationDetailsContext = createContext();
  export const NominationDetailsContextProvider = ({ children }) => {
    let history = useHistory();
    const globalState = useContext(store);
    let location = useLocation();
    const columns = [
      {
        dataField: "date",
        text: "Date",
        sort: true,
        formatter:(cellContent, row)=>(
          <div>
          {row.updatedAt &&
          <Moment date={new Date(row.updatedAt)} format="DD/MM/YYYY"></Moment >
          }
          </div>
        ),
        headerClasses: 'tableHeader'
      },
      {
        dataField: "description",
        text: "Actions",
        sort: true,
        headerClasses: 'tableHeader'
      },
      {
        dataField: "remarks",
        text: "Remarks",
        sort: true,
        headerClasses: 'tableHeader'
      },
    ];

    const [nominationDetails, setNominationsDetails] = useState([]);
    const [nominationDetailsLogs, setNominationsDetailsLogs] = useState([]);
    const [rejectNomination, setRejectNominationClick] = useState(false);
    const [rejectionNote, setRejectionNote] = useState("");
    const [columnData, setColumnData] = useState(columns);
    const [selectedId, setSelectedId] = useState("");
    const [showMandatoryValidation, setShowMandatoryValidation] = useState(false);

    const onRejected = () => {
      if(rejectionNote.trim() !== ""){
      setNominationStatusApi("Rejected",rejectionNote);
      }
      else{
        setShowMandatoryValidation(true);
      }
    };
    const onApproved = () => {
      setNominationStatusApi("Approved","");
    };
    const getNominationDetailsFromApi = async (id) => {
      API.getNominationDetails(id,globalState.state.token)
        .then(async (response) => {
          const data = response.data.nomination_details;
          setNominationsDetails(data);
        })
        .catch((e) => {
          console.log("error of Nomination Details list", e);
        });
      API.getNominationDetailLogs(id,globalState.state.token)
      .then(async (response) => {
        const data = response.data.logs_list;
        setNominationsDetailsLogs(data);
      })
      .catch((e) => {
        console.log("error of Nomination Details Logs list", e);
      });
    };
    const setNominationStatusApi = async (status,note) => {
      API.setNominationStatus(selectedId,status,note,globalState.state.token)
        .then(async (response) => {
          const data = response.data;
          setRejectNominationClick(false);
          history.push({
            pathname: routes.NOMINATION_PAGE
          });
        })
        .catch((e) => {
          console.log("error of nomination status change", e);
        });
    };
    useEffect(async () => {
      if(location.state && location.state.selectedId !==undefined){
        setSelectedId(location.state.selectedId);
        getNominationDetailsFromApi(location.state.selectedId);
      }
    }, []);
  
    const providerValue = {
      nominationDetails,
      nominationDetailsLogs,
      rejectNomination,
      columnData,
      selectedId,
      setNominationsDetails,
      setNominationsDetailsLogs,
      setColumnData,
      setSelectedId,
      setRejectNominationClick,
      rejectionNote,
      setRejectionNote,
      onRejected,
      onApproved,
      showMandatoryValidation,
      setShowMandatoryValidation
    };
  
    return (
      <NominationDetailsContext.Provider value={providerValue}>
        {children}
      </NominationDetailsContext.Provider>
    );
  };
  